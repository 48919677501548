import React from 'react'
import { Flex, Flexed, Input, Selectbox, Wrapper } from '../../Shared'
import Select from 'react-select';
import { useState } from 'react';


const slits = [
    { value: '1', label: '1 Slit' },
    { value: '2', label: '2 Slits' },
    { value: '3', label: '3 Slits' },
  ];

const NewQuote = () => {

    const [showpocketsize, setShowpocketsize] = useState(false)
    const [quoteinfo, setQuoteinfo] = useState({glue:""})

const handlepocketsize = (e) => {
    console.log(e)
    if(e.target.value === "11" || e.target.value === "12" || e.target.value === "21" || e.target.value === "22"){setShowpocketsize(true)}else{setShowpocketsize(false)}
}




  return (
    <Wrapper>
        <Flexed>
            <Flex textAlign="center">CFP Quote System</Flex>
        </Flexed>

            <Flexed>
            <Flex textAlign="center"> <h4>Pocket Folders</h4></Flex>
        </Flexed>


        <Flexed>  
                    <Flex >
                    Quantity<br />
                        <Input type="text" />
                        </Flex>
                        <Flex>Pocket Folder Type <br />
                        <Selectbox style={{fontSize:".9rem"}} onChange={e => handlepocketsize(e)}>
                            <option value="13" >1 Pocket 1 BC - 3"</option>
                            <option value="123" >1 Pocket 2 BC's - 3"</option>
                            <option value="24" >2 Pocket 1 BC - 4"</option>
                            <option value="224" >2 Pocket 2 BC's - 4"</option>
                            <option value="11" >1 Pocket 1 BC - Custom</option>
                            <option value="12" >1 Pocket 2 BC's - Custom</option>
                            <option value="21" >2 Pocket 1 BC - Custom</option>
                            <option value="22" >2 Pocket 2 BC's - Custom</option>
                           
                        </Selectbox> 
                        {showpocketsize && <span style={{padding:"15px 0px"}}><Input type="text"  width="45%"/> X <Input type="text" width="45%"/></span>}
                         </Flex>
                        <Flex> Dies<br />
                        <Selectbox>
                            <option value="">None</option>
                            <option value="">1 Slits</option>
                            <option value="">2 Slits</option>
                            <option value="">3 Slits</option>
                        </Selectbox>
                        </Flex>
                      
                      
                    <Flex>
                    Pocket Size<br />
                    <Selectbox >
                            <option value=""></option>
                            <option value="3">3"</option>
                            <option value="4">4"</option>
                            <option value="custom">Custom</option>
                        </Selectbox>
                  
                    </Flex>
                </Flexed>
         

         <Flexed>
            <Flex>Quantity<br /><Input type="text" value={quoteinfo.glue}/></Flex>
         </Flexed>
      
    </Wrapper>
  )
}

export default NewQuote