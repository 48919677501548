import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const NewLocation = ({onCancel,customerid,setReset,reset}) => {

    const[ newinfo, setNewinfo] = useState({itemid:uuidv4()})


    const addNewLocation = async(e) => {
e.preventDefault()
const y = {
    customerid:customerid,
    info:newinfo
}
console.log(y)
        await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/customers/addlocation`,y)
       .then(res => {setReset(!reset);onCancel(true)})
       .catch(err  => console.log(err))
    }
  return (

    <form onSubmit={e => addNewLocation(e)}>
    <Flexed gap="20px">
        <Flex><Label>Location Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
        <Flex><Label>Address 1</Label><Input type="text" value={newinfo?.address1} onChange={e => setNewinfo({...newinfo,address1:e.target.value})}/></Flex>
        <Flex><Label>Address2</Label><Input type="text" value={newinfo?.address2} onChange={e => setNewinfo({...newinfo,address2:e.target.value})}/></Flex>
        <Flex><Label>Phone</Label><Input type="text" value={PhoneNumber(newinfo?.phone)} onChange={e => setNewinfo({...newinfo,phone:e.target.value})}/></Flex>
    </Flexed>
        <Flexed gap="20px">
        <Flex><Label>City</Label><Input type="text" value={newinfo?.city} onChange={e => setNewinfo({...newinfo,city:e.target.value})}/></Flex>
        <Flex flex=".3"><Label>State</Label><Input type="text" value={newinfo?.state} onChange={e => setNewinfo({...newinfo,state:e.target.value})} /></Flex>
        <Flex><Label>Zip</Label><Input type="text" value={newinfo?.zip} onChange={e => setNewinfo({...newinfo,zip:e.target.value})}/></Flex>
        <Flex><Label>Attn</Label><Input type="text" value={newinfo?.attn} onChange={e => setNewinfo({...newinfo,attn:e.target.value})}/></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Delete</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px">Add Location</Button></Flex>
    </Flexed>
    </form>
    
  
  )
}

export default NewLocation

