
import styled from 'styled-components'
import LOGO from '../Assets/CFPLOGO.png'
import { Img,Input,Button } from '../Shared';
import { useContext,useState,useEffect } from 'react'
import UserContext from '../Context/UserContext'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = styled.section`
width:100%;
height:100vh;
position:relative;
background:#ffffff;
`;

const Logo = styled.div`
width:100%;
position:relative;

top:40px;
text-align:center;
`;

const Main = styled.div`
width:100%;
position:relative;
background:var(--plsBlue);
color:white;



`;

const FormWrap = styled.div`
width:250px;
position:relative;
margin:0 auto;
padding:20px 0px;

`;

const Forgot = styled.div`
width:250px;
position:relative;
margin:0 auto;

text-align:right;
color:var(--plsGreen);
font-size:var( --tinyFont);
padding:0px 0px 5px 0px;
`;

const Log = styled.div`
text-align:center;

padding:15px 0px;
`;

const Rites = styled.div`
text-align:center;

position:relative;
color:var(--plsGreen);
font-size:var( --tinyFont);
bottom:-15px;
`;





const Login = () => {

    const [info, setInfo] = useState()
   
    const {userInfo,LoginCheck,setUserinfo} = useContext(UserContext);
    useEffect(() => {
      //  localStorage.getItem('logged') && setUserinfo({...userInfo,logged:true})
        
        },[])


console.log(userInfo)
  return (
    <Wrapper>
   
        <Logo><Img src={LOGO} width="30%"/></Logo>
        <Main>
        <FormWrap>
            Employee <br />
          <Input type="text" textTransform="none" value={info?.username} onChange ={ (e) => setInfo({...info, username:e.target.value})}/>
          <br /><br />
          Password<br />
          <Input type="password" textTransform="none" value={info?.password} onChange ={ (e) => setInfo({...info, password:e.target.value})}/>  
          <Forgot>Forgot Password</Forgot>
          <Log><Button onClick={e => LoginCheck(info)}>Log In</Button></Log>
          <Rites>HighGround Software. All Rights Reserved.</Rites>
            </FormWrap>
        </Main>


    
    </Wrapper>
  );
}

export default Login