import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react'
//import Portal from './Portal';
import { currentStatusColor } from '../../Util/Util'
import multiMonthPlugin from '@fullcalendar/multimonth'
import React from 'react'
import { Flex, Flexed } from '../../Shared';
//import Notassigned from './Notassigned';
//import UnassignPortal from './UnassignPortal'
//import AddPortal from './AddPortal';





const NewCal = ({color,machine}) => {
    const param = useParams()
    const [events, setEvents] = useState() 
    const [nonevents, setNonEvents] = useState() 
    const [showportal, setShowportal] = useState(false) 
    const [showportal2, setShowportal2] = useState(false) 

    const [currentItem, setCurrentItem] = useState() 
    const [currentItem2, setCurrentItem2] = useState() 
    const [reset, setReset] = useState() 
    const [un, setUn] = useState(false) 


useEffect(() => {
    const getEvents = async() => {
      await  axios.get(` https://cfpapi-6ab062006fa0.herokuapp.com/schedules/all/${machine}`)
        .then(res => {console.log(machine);setEvents(res.data)})
        .catch(err => console.log(err))
       }
       const getNonEvents = async() => {
       /* await  axios.post(`http://localhost:5003/schedule/unassigned/${press}`)
        .then(res => {console.log(res);setNonEvents(res.data)})
        .catch(err => console.log(err))
        */
       }
       getEvents()
       //getNonEvents()

},[reset])


/*    useEffect(() => {
  
        const getEvents = async() => {
         await  axios.get(`http://localhost:5003/schedule/all/${param.type}`)
         .then(res => {console.log(res);setEvents(res.data)})
         .catch(err => console.log(err))
        }
      
        //setNotevents(COMINGVENTS)
        getEvents()
      },[])
*/

const handleClick = () => {

}

const addhours = (startDate,hours) => {
  let y =  new Date(startDate.getTime() + (hours*60*60*1000))
   return y;
 }




const drop = async(info) => {
   
console.log(info)
    let y = addhours(info.event.start,info.event.extendedProps.hours)
  const newdayinfo = {

    id:info.event.id,
    start:info.event?.start,
    end:y,
    hours:info.event.extendedProps.hours,
    title:info.event.title,
    machine:info.event.extendedProps.machine,
    status:1
  }
 await  axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/schedules/update`,newdayinfo)
  .then(res => {setReset(!reset)})
  .catch(err => console.log(err))
  console.log(newdayinfo)
  }


  return (
    <Flexed>

 <div style={{position:"absolute", top:"5px",left:"0",zIndex:"5",fontSize:"calc(1rem + .4vw)"}} onClick={e => setUn(!un)}>Not Assigned - <span style={{color:"orange",fontSize:"calc(1.2rem + .4vw)"}}>{nonevents?.length}</span></div>
 {/*showportal && <Portal setShowportal={setShowportal} currentItem={currentItem} setReset={setReset} reset={reset}/>*/}
 {/*showportal2 && <UnassignPortal setShowportal={setShowportal2} currentItem={currentItem2} setReset={setReset} reset={reset}/>*/}
 
   
      <Flex flex="3">
      

         <FullCalendar
         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,multiMonthPlugin]}
         initialView="timeGridWeek"
         headerToolbar={{
             start:'',
             center: 'title',
         end:"timeGridWeek,timeGridDay,dayGridMonth,multiMonthYear,list,'',prev,next"
           }}
        hiddenDays={[ 0 ]}
         events={events}
        eventClick={(e) => {console.log(e);setCurrentItem(e);setShowportal(true)}}
        editable
        eventDrop = {info => drop(info)} 
        eventTextColor={"white"}
       // eventStartEditable
     eventDurationEditable={false}
     droppable={true}
      /*businessHours={{
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [ 1, 2, 3, 4 ], // Monday - Thursday
      
        startTime: '10:00', // a start time (10am in this example)
        endTime: '18:00', // an end time (6pm in this example)
       }}*/
      slotMinTime={"05:00:00"}
      slotMaxTime={"22:00:00"}
      allDaySlot={false}
      contentHeight={"700px"}
 
      />
      </Flex>
       </Flexed>
  )
}
  
export default NewCal