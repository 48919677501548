import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const UpdateLocation = ({onCancel,customerid,setReset,reset,location}) => {

    const[ newinfo, setNewinfo] = useState({location})


    const updateLocation = async(e) => {
e.preventDefault()
const y = {
    itemid:newinfo.location.itemid,
    info:newinfo
}
       await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/customers/updatelocation`,y)
        .then(res => {console.log(res.data);setReset(!reset);onCancel(true)})
      .catch(err  => console.log(err))
    console.log(y)
    }
  return (

    <form onSubmit={e => updateLocation(e)}>
    <Flexed gap="20px">
        <Flex><Label>Location Name</Label><Input type="text" value={newinfo.location?.name} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,name:e.target.value}})} required/></Flex>
        <Flex><Label>Address 1</Label><Input type="text" value={newinfo.location?.address1} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,address1:e.target.value}})}/></Flex>
        <Flex><Label>Address2</Label><Input type="text" value={newinfo.location?.address2} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,address2:e.target.value}})}/></Flex>
        <Flex><Label>Phone</Label><Input type="text" value={PhoneNumber(newinfo.location?.officephone)} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,officephone:e.target.value}})}/></Flex>
    </Flexed>
        <Flexed gap="20px">
        <Flex><Label>City</Label><Input type="text" value={newinfo.location?.city} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,city:e.target.value}})}/></Flex>
        <Flex flex=".3"><Label>State</Label><Input type="text" value={newinfo.location?.state} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,state:e.target.value}})} /></Flex>
        <Flex><Label>Zip</Label><Input type="text" value={newinfo.location?.zip} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,zip:e.target.value}})}/></Flex>
        <Flex><Label>Attn</Label><Input type="text" value={newinfo.location?.attn} onChange={e => setNewinfo({...newinfo,location:{...newinfo.location,attn:e.target.value}})}/></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Delete</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px">Update Location</Button></Flex>
    </Flexed>
    </form>
    
  
  )
}

export default UpdateLocation



