import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const NewContact = ({onCancel,customerid,setReset,reset}) => {

    const[ newinfo, setNewinfo] = useState({invoices:false,itemid:uuidv4()})


    const addNewContact = async(e) => {
e.preventDefault()
const y = {
    customerid:customerid,
    info:newinfo
}
        await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/customers/addcontact`,y)
        .then(res => {setReset(!reset);onCancel(true)})
        .catch(err  => console.log(err))
    }
  return (

    <form onSubmit={e => addNewContact(e)}>
    <Flexed gap="20px">
        <Flex><Label>First Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
        
        <Flex><Label>Title</Label><Input type="text" value={newinfo?.title} onChange={e => setNewinfo({...newinfo,title:e.target.value})}/></Flex>
        <Flex><Label>Email</Label><Input type="email" value={newinfo?.email} onChange={e => setNewinfo({...newinfo,email:e.target.value})}/></Flex>
    </Flexed>
        <Flexed gap="20px">
        <Flex><Label>Work Phone</Label><Input type="text" value={PhoneNumber(newinfo?.officephone)} onChange={e => setNewinfo({...newinfo,officephone:e.target.value})}/></Flex>
        <Flex flex=".3"><Label>Ext</Label><Input type="text" value={newinfo?.ext} onChange={e => setNewinfo({...newinfo,ext:e.target.value})} /></Flex>
        <Flex><Label>Mobile Phone</Label><Input type="text" value={PhoneNumber(newinfo?.cell)} onChange={e => setNewinfo({...newinfo,cell:e.target.value})}/></Flex>
        <Flex><Label>Invoices</Label><Selectbox value={newinfo?.invoices} onChange={e => setNewinfo({...newinfo,invoices:e.target.value})}><option value="No">No</option><option value="Yes">Yes</option></Selectbox></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Delete</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px">Add Contact</Button></Flex>
    </Flexed>
    </form>
    
  
  )
}

export default NewContact