import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const UpdateContact = ({onCancel,customerid,setReset,reset,contact}) => {

    const[ newinfo, setNewinfo] = useState({contact})


    const updateContact = async(e) => {
        
        e.preventDefault()
        const y = {
            itemid:newinfo.contact.itemid,
            info:newinfo
        }
               await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/customers/updatecontact`,y)
                .then(res => {setReset(!reset);onCancel(true)})
              .catch(err  => console.log(err))
        
            }

  return (

    <form onSubmit={e => updateContact(e)}>
    <Flexed gap="20px">
        <Flex><Label>Contact Name</Label><Input type="text" value={newinfo.contact?.name} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,name:e.target.value}})} required/></Flex>
        
        <Flex><Label>Title</Label><Input type="text" value={newinfo.contact?.title} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,title:e.target.value}})}/></Flex>
        <Flex><Label>Email</Label><Input type="email" value={newinfo.contact?.email} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,email:e.target.value}})}/></Flex>
    </Flexed>
        <Flexed gap="20px">
        <Flex><Label>Work Phone</Label><Input type="text" value={PhoneNumber(newinfo.contact?.officephone)} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,officephone:e.target.value}})}/></Flex>
        <Flex flex=".3"><Label>Ext</Label><Input type="text" value={newinfo.contact?.ext} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,ext:e.target.value}})} /></Flex>
        <Flex><Label>Mobile Phone</Label><Input type="text" value={PhoneNumber(newinfo.contact?.cell)} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,cell:e.target.value}})}/></Flex>
        <Flex><Label>Invoices</Label><Selectbox value={newinfo.contact?.invoices} onChange={e => setNewinfo({...newinfo,contact:{...newinfo.contact,invoices:e.target.value}})}><option value="No">No</option><option value="Yes">Yes</option></Selectbox></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button type="button"  height="30px" onClick={onCancel}>Delete</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button type="button" height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button type="submit" height="30px" style={{whiteSpace:"nowrap"}}>Update Contact</Button></Flex>
    </Flexed>
    </form>
    
  
  )
}

export default UpdateContact



