import {useState,useEffect} from 'react'
import Table from '../../components/table/Table';
import  { CUSTOMERLIST } from '../../components/table/COLUMNS'
//import DATA from '../../components/table/DATA.json'
import DATA from '../../components/table/NewData.json'
import axios from 'axios'
import { Flex, Flexed, PhoneNumber, Wrapper,AddCustomer, Input, Fixed,List } from '../../Shared';
import { useNavigate } from 'react-router-dom';



const Customers = () => {

    const [customers, setCustomers] = useState([])
    const [search, setSearch] = useState("")

const nav = useNavigate()
useEffect(() => {
const getCustomers = async() => {
await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getall')
.then(res => {console.log(res.data);setCustomers(res.data)})
.catch((err) => console.log(err))
}
getCustomers()
},[])


  return (
    <div style={{padding:"0px 20px",position:"relative"}}>
      <Fixed padding="30px 0px 0px 0px" style={{zIndex:"10"}}>
  <Flexed>
            <Flex fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="var(--plsBlue)" textAlign="center" padding="10px 0px">CFP Vendors</Flex>
        </Flexed>
        <Flexed >
          <Flex ><Input type="text" height="30px" placeholder="Search Vendors" onChange={e => setSearch(e.target.value)}/></Flex>
         <Flex textAlign="right"><AddCustomer  onClick={() => nav('/vendors/new')} style={{position:"relative",top:"4px"}}/></Flex> 
         </Flexed>
<Flexed bgColor="var(--plsGreen)" padding="6px 4px" color="white" margin="0">
  <Flex flex="2">Customer</Flex>
  <Flex flex="2">Address</Flex>
  <Flex>Main Phone</Flex>
  <Flex>Contact</Flex>
  <Flex>Contact Email</Flex>
</Flexed>
</Fixed>
<List >
{customers?.filter(item => {return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px" style={{flexWrap:"wrap"}} borderBottom="1px solid lightgrey"  >
            <Flex flex="2" onClick={() => nav(`/vendors/${customer._id}`)}>{customer?.name}</Flex>
            <Flex flex="2" onClick={() => nav(`/vendors/${customer._id}`)}>{customer.address !== null && customer?.address[0]?.address1}</Flex>
            <Flex onClick={() => nav(`/vendors/${customer._id}`)}>{customer.address !== null && PhoneNumber(customer?.address[0]?.officephone)}</Flex>
            <Flex onClick={() => nav(`/vendors/${customer._id}`)}>{customer.address !== null && customer?.contacts[0]?.name}</Flex> 
            <Flex style={{minWidth:"0"}} onClick={() => customer?.contacts[0]?.email > "" ? window.location = `mailto:${customer?.contacts[0]?.email}`: nav(`/customers/${customer._id}`)}>{customer.address !== null && customer?.contacts[0]?.email}</Flex> 
            </Flexed>
            
            </>
         })}

</List>


</div>
  )
}

export default Customers