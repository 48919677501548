import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import Calender from '../Schedules/Calender';
//import M110 from '../Schedules/M110';
//import Digital from '../Schedules/Digital';
//import Anicolor from '../Schedules/Anicolor';
import NewCal from './NewCal';
import {AiFillFileAdd} from 'react-icons/ai'
//import AddEvent from './AddEvent';
//import AddPortal from './AddPortal';
import { useState } from 'react';




const Schedules = () => {

  const [showportal3, setShowportal3] = useState(false) 




  return (
    <div style={{marginTop:"20px"}}>
      {/*showportal3 && <AddPortal setShowportal={setShowportal3}/>*/}
   
      {/*/////////////////////////////////////////PRESS////////////////////////////////////////////////////////////////////*/}
      <Tabs  defaultIndex={localStorage.getItem('scheduletabs1') || 0}>
    <TabList>

      <Tab value={0} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>L Miller</Tab>
      <Tab value={1} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>S Miller</Tab>
      <Tab value={2} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Yawa</Tab>
      <Tab value={3} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Saro</Tab>
      <Tab value={4} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Kluge 1</Tab>
      <Tab value={5} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Kluge 2</Tab>
      <Tab value={6} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Kluge 3</Tab>
      <Tab value={6} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Converter 1</Tab>
      <Tab value={6} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Converter 2</Tab>
      <Tab value={6} onClick={e => localStorage.setItem('scheduletabs1', e.target.value)}>Router</Tab>
      <AiFillFileAdd onClick={() => {setShowportal3(true)}} style={{position:"relative",color:"var(--plsGreen)",fontSize:"+1.5rem",top:"4px"}}/>
    </TabList>


    <TabPanel >
    <NewCal machine="L Miller" />
    </TabPanel>
    <TabPanel>
    <NewCal machine="S Miller"/>
    </TabPanel>
    <TabPanel>
    <NewCal machine="Yawa"/>
    </TabPanel>
    <TabPanel>
    <NewCal color="Saro"/>
    </TabPanel>
    <TabPanel >
    <NewCal press="Kluge 1" />
    </TabPanel>
    <TabPanel >
    <NewCal press="Kluge 2" />
    </TabPanel>
    <TabPanel >
    <NewCal press="Kluge 3" />
    </TabPanel>
    <TabPanel >
    <NewCal press="Converter 1" />
    </TabPanel>
    <TabPanel >
    <NewCal press="Converter 2" />
    </TabPanel>
    <TabPanel >
    <NewCal press="Router" />
    </TabPanel>
  </Tabs>
  </div>
  )
}

export default Schedules