
import { createContext, useState, useEffect } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export const UserContext = createContext();

export const UserProvider = ({children}) => {
const [userInfo, setUserinfo] = useState({});
const [empid, setEmpid] = useState('');
const [reset, setReset] = useState(false);

useEffect(() => {
  if(sessionStorage.getItem('trackid')){

    const getEmployee = async() => {
      let rr = {"id":sessionStorage.getItem('trackid')}
      await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/getemployee',rr)
      .then(res => {
  console.log(res.data)
        setUserinfo({...res.data,newname:res.data.preferredName+" "+res.data.lastName.charAt(0)})  
      })
  
      .catch(err => console.log(err))
    }
    getEmployee()
  }

},[])



const updateEmployeeInfo = async() => {
 // axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/update/employee',empinfo)
 // .then(res => toast.success('Employee Updated'))
 // .catch(err => console.log(err))
 // console.log(empinfo)
}

const LoginCheck = async(info) => {
  console.log(info)
if(!info?.username ){
  return toast.error('Username can not be empty')
}
if(!info?.password ){
  return toast.error('Password can not be empty')
  }
const r = {
  "username":info.username,
  "password":info.password,
}
await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/login',r)
.then(res => {
  console.log(res);

  setUserinfo({...res.data.userinfo,newname:res.data.userinfo?.preferredName+" "+res.data?.userinfo?.lastName?.charAt(0)});
sessionStorage.setItem('trackid',res.data.userinfo._id);
 // sessionStorage.setItem('name',newname);
 // sessionStorage.setItem('access',res.data.userinfo.access);
 // sessionStorage.setItem('empId',res.data.userinfo.empId);
})
.catch(err => {console.log(err);toast.error(err.response?.data?.message)})

}




const close = () => {
  setUserinfo({})
  sessionStorage.clear();
}


console.log(userInfo)


  //setUserinfo({...userInfo,logged:true})
 // sessionStorage.setItem("logged",true)
 // setUserinfo({
 //   logged:sessionStorage.getItem("logged"),
     // name:info.firstName+" "+info.lastName.charAt(0),
     // auth:info.auth
   // })

//await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/login',info)
//.then(res => {console.log(res)
 //const info = res.data.userinfo;

////ocalStorage.setItem('logged', true);
///localStorage.setItem('name', info.firstName+" "+info.lastName.charAt(0));
//toast.success(`Welcome Back ${info.firstName}`)
//})
//.catch(err => {console.log(err);toast.error(err.response.data.message,{pauseOnHover: false})})





//setUserinfo({...userInfo,logged:true,name:"User"})







  return (
    <UserContext.Provider value={{userInfo,LoginCheck,setUserinfo,close}}>
          <ToastContainer position="top-center" theme="dark" pauseOnHover={false}/>
        {children}
    </UserContext.Provider>
  )
}

export default UserContext