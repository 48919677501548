import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/CFPLOGO.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.9rem;
font-weight:bold;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-end"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};

`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({jobinfo}) {
  let componentRef = useRef();
 jobinfo = jobinfo
const [message, setMessage] = useState("")
const [totalsheets, setTotalsheets] = useState(0)


  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo(jobinfo)
  })


  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 console.log(jobinfo)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint jobinfo={jobinfo} totalsheets={totalsheets} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    

  }

  render() {
    return (
      <Flex>
       
     <Wrapper>
     <Flexed >
       <Flex> <Img src={LOGO} style={{height:"100px"}} /></Flex>
<Flex flex="0">CFP Job# </Flex>
<Flex flex="0" width="30%"><Input style={{fontWeight:"bold",paddingLeft:"15px",fontSize:"1.4rem"}} width="130px" type="text"  defaultValue={this.props.jobinfo.jobid}/> </Flex>
    </Flexed>

    <Flexed>
<Flex flex=".5" >Customer </Flex>
<Flex flex="1.8"><Input defaultValue={this.props.jobinfo?.customername} type="text" /> </Flex>
<Flex flex="0">Received stock&nbsp; </Flex>
<Flex style={{borderBottom:"1px solid black",fontSize:".9rem"}}>{this.props.jobinfo?.recievedstock === "Invalid Date" ? null: <Moment format="MM/DD/YYYY">{this.props.jobinfo?.recievedstock}</Moment>} </Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Ticket &nbsp;</Flex>
<Flex><Input defaultValue={this.props.jobinfo?.ticket}  type="text" /> </Flex>
</Flexed>
<Flexed>
<Flex flex=".5" >Contact </Flex>
<Flex flex="1.8"><Input defaultValue={this.props.jobinfo?.contact}  type="text" /> </Flex>
<Flex flex="0"> Received Film&nbsp;&nbsp; </Flex>
<Flex style={{borderBottom:"1px solid black",fontSize:".9rem"}}>{this.props.jobinfo?.recievedfilm === "Invalid Date" ? <Moment format="MM/DD/YYYY">{this.props.jobinfo?.recievedfilm}</Moment> : null} </Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Finish &nbsp;</Flex>
<Flex><Input defaultValue={this.props.jobinfo?.finish} type="text" /> </Flex>
</Flexed> 
<Flexed>
<Flex flex=".5" >Phone# </Flex>
<Flex flex="1.8"><Input defaultValue={this.props.jobinfo?.mainphone}  type="text" /> </Flex>
<Flex flex="0">  Need By&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </Flex>
<Flex style={{borderBottom:"1px solid black",fontSize:".9rem"}}>{this.props.jobinfo?.needby === "Invalid Date" ? <Moment format="MM/DD/YYYY">{this.props.jobinfo?.needby}</Moment> : null}</Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">C/B/C &nbsp;</Flex>
<Flex><Input defaultValue={this.props.jobinfo?.cbc}  type="text" /> </Flex>
</Flexed> 



<div style={{position:"relative",padding:"0",margin:"0",marginBottom:"-5px"}}>
<Flexed >
<Flex><Textarea minRows="6" value={this.props.jobinfo?.text1} /></Flex>
<Flex><Textarea minRows="6"value={this.props.jobinfo?.text2} /></Flex>
</Flexed>
<div style={{position:"absolute",bottom:"10px",left:"50%",height:"50px",width:"45%"}}>

</div>
</div>

<div ><Headerspan>Foil Stamp & Emboss</Headerspan></div>

<Flexed style={{background:"lightGrey"}}>
<Flex>Process</Flex>
    <Flex>Stamp</Flex>
    <Flex>Plates</Flex>
    <Flex>Passes</Flex>
    <Flex>On</Flex>
    <Flex flex="1.5">Area Size</Flex>
    <Flex flex="1.5">Sheet Size</Flex>
    <Flex>Sheets</Flex>
    <Flex flex="2">Overs</Flex>
  </Flexed>
  {
    
    this.props.jobinfo.stamp?.map(item => (
  
        <Flexed style={{borderBottom:"1px solid lightgrey"}}>
    <Flex>{item.process}</Flex>
    <Flex>{item.stampcount}</Flex>
    <Flex>{item.plates}</Flex>
    <Flex>{item.passes}</Flex>
    <Flex>{item.on}</Flex>
    <Flex flex="1.5">{item.areal+" X "+item.areaw}</Flex>
    <Flex flex="1.5">{item.sheetl+" X "+item.sheetw}</Flex>
    <Flex>{item?.overpercent > 0 ? item.overpercentquantity+" Incl -" : item?.sheets}</Flex>
    <Flex flex="2">{item?.overpercent > 0 ? item?.overpercent+"% Overs" : item?.overs}</Flex>
  </Flexed>
    ))
}


{!this.props.jobinfo.stamp || this.props.jobinfo.stamp.length === 0 && <p style={{width:"100%",padding:"6px",textAlign:"center",minHeight:"100px"}}>No Foil Stamp or Embossing</p>}
<hr style={{border:"1px solid Black",margin:"3px 0px"}}/>
<div ><Headerspan>Die Room</Headerspan></div>


<Flexed>
<Flex flex="0"><Label>One Cutting Die</Label></Flex>
<Flex ><Input   type="text" defaultValue={this.props.jobinfo?.cuttingdie}/>&nbsp; </Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Label>On</Label></Flex>
<Flex flex="6"><Input   type="text" defaultValue={this.props.jobinfo?.cuttingon}/>&nbsp; </Flex>
<Flex flex="0"><Label>Die #</Label></Flex>
<Flex ><Input   type="text" defaultValue={this.props.jobinfo?.cuttingdie1}/>&nbsp; </Flex>
<Flex flex="0"><Label></Label></Flex>
<Flex flex="3"><Input   type="text" defaultValue={this.props.jobinfo?.cuttingdiefurnished && this.props.jobinfo?.cuttingdiefurnished}/>&nbsp; </Flex>
<Flex flex="0"><Label>Die #</Label></Flex>
<Flex ><Input   type="text" defaultValue={this.props.jobinfo?.cuttingdie2}/>&nbsp; </Flex>

</Flexed>



    <hr style={{border:"1px solid Black",margin:"3px 0px"}}/>
<div ><Headerspan>Press Room</Headerspan></div>

<Flexed>
<Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.pressdiecut}   />&nbsp; </Flex>
<Flex flex="0" >Die Cut</Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.pressdiecut2}   />&nbsp; </Flex>
<Flex flex="0" >2nd Die Cut</Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.presskiss}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Kiss Cut</Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.pressperf}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Perforate</Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.pressscore}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Score</Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.pressstrip}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Strip</Flex>
<Flex flex=".3">  </Flex>
</Flexed>
<Flexed>
<Flex flex="2"><Input defaultValue={this.props.jobinfo?.presssheetscut}  type="text" />&nbsp; </Flex>
<Flex flex="0">Sheets Cut</Flex>
<Flex ><Input defaultValue={this.props.jobinfo?.presssheetstimes}  type="text" width="100%"/>&nbsp; </Flex>
<Flex flex="0">Times for</Flex>
<Flex ><Input defaultValue={this.props.jobinfo?.presssheetsimpressions}  type="text" />&nbsp; </Flex>
<Flex flex="0">Impressions</Flex>
<Flex flex=".3">  </Flex>

<Flex ><Input defaultValue={this.props.jobinfo?.presssheetson}  type="text" />&nbsp; </Flex>
<Flex flex="0">on</Flex>
<Flex ><Input defaultValue={this.props.jobinfo?.presssheetsl}  type="text" />&nbsp; </Flex>
<Flex flex="0">X</Flex>
<Flex ><Input defaultValue={this.props.jobinfo?.presssheetsw}  type="text" />&nbsp; </Flex>
<Flex flex="0">Sheet Size</Flex>
</Flexed>

<Flexed>
<Flex flex="0">Quantity Sheets</Flex>
<Flex ><Input   type="text" width="100%" defaultValue={this.props.jobinfo?.presssheets}/>&nbsp; </Flex>
<Flex flex=".1">  </Flex>
<Flex flex="0">Quantity Pieces</Flex>
<Flex ><Input   type="text" width="100%" defaultValue={this.props.jobinfo?.presspieces}/>&nbsp; </Flex>

<Flex flex=".3">  </Flex>
<Flex >{this.props.jobinfo?.pressdiecut2 && <>2ND DIE CUT REQUIRED</>}</Flex>
<Flex flex="2">  </Flex>
</Flexed>

<Flexed padding="5px 0px">
<Flex flex="0"><Input   type="checkbox" checked={this.props.jobinfo?.neccessary}/>&nbsp; </Flex>
<Flex  >Neccessary</Flex>
<Flex flex=".1">  </Flex>
<Flex flex="0"><Input   type="checkbox" checked={this.props.jobinfo?.complete}/>&nbsp; </Flex>
<Flex  >Complete</Flex>
<Flex flex=".1">  </Flex>
<Flex flex="0"><Input   type="checkbox" checked={this.props.jobinfo?.repeat}/>&nbsp; </Flex>
<Flex  >Repeat</Flex>
<Flex flex="2.7">  </Flex>

<Flex  flex="1.5">{this.props.jobinfo?.pressoverpercent > 0 ? "Run "+this.props.jobinfo.pressoverpercent+"% Overs" : this.props.jobinfo.overs}</Flex>
<Flex flex="1">  </Flex>
</Flexed>
<hr style={{border:"1px solid Black",margin:"3px 0px"}}/>
<div ><Headerspan>Glue Room</Headerspan></div>
<Flexed >
    <Flex flex="0">Glue</Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.glue}/></Flex>
    <Flex flex="0">Pieces with</Flex>
    <Flex flex="1"><Input type="text"  defaultValue={this.props.jobinfo?.gluepieces1}/></Flex>
    <Flex flex="0">/</Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.gluepieces2} /></Flex>
    <Flex flex="0">Flaps</Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0">Final Fold</Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.gluefinalfold} /></Flex>
    <Flex flex="0">Pieces</Flex>
</Flexed>
<Flexed padding="5px 0px">
<Flex flex="0"><Input checked={this.props.jobinfo?.gluekraftwrap}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Kraft Wrap In</Flex>
<Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.gluekraftquantity}/></Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.glueboxin}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Box In</Flex>
<Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.glueboxquantity}/></Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.glueshrinkwrap}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Skrink Wrap In</Flex>
<Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.glueshrinkquantity}/></Flex>
<Flex flex=".3">  </Flex>
<Flex flex="0"><Input checked={this.props.jobinfo?.gluerubberbandin}  type="checkbox" />&nbsp; </Flex>
<Flex flex="0">Rubber Band In</Flex>
<Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.gluerubberquantity}/></Flex>
<Flex flex=".3">  </Flex>
<Flex  flex="1.5">{this.props.jobinfo?.glueoverpercent > 0 ? "Run "+this.props.jobinfo.glueoverpercent+"% Overs" : this.props.jobinfo.glueovers}</Flex>
<Flex flex=".3">  </Flex>
</Flexed>

<hr style={{border:"1px solid Black",margin:"3px 0px"}}/>
<div ><Headerspan>Shipping Instructions</Headerspan></div>
<Flexed >
<Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.deliver} /></Flex>
    <Flex flex="0"><Label>Deliver&nbsp;</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.cod}/></Flex>
    <Flex flex="0"><Label>C.O.D&nbsp;</Label></Flex>
    <Flex><Input type="text"  defaultValue={this.props.jobinfo?.codtext}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.pickup}/></Flex>
    <Flex flex="0"><Label>Customer Pick-up&nbsp;</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.otherpickup}/></Flex>
    <Flex flex="0"><Label>Other&nbsp;</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.ups}/></Flex>
    <Flex flex="0"><Label>UPS&nbsp;</Label></Flex>
    <Flex flex=".5"></Flex>
</Flexed>

<Flexed textAlign="flex-start" style={{textAlign:"left"}}>
    <Flex>{this.props.jobinfo?.deliver || this.props.jobinfo?.cod ? this.props.jobinfo?.address : ""}</Flex>
</Flexed>
<Flexed >
    <Flex flex="0"><Label>P.O.#</Label></Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.po}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Job #</Label></Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.customerjobid}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Bid #</Label></Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.bid}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>CFP Job #</Label></Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.jobid}/></Flex>
    <Flex flex=".5"></Flex>
</Flexed>
<Flexed>
    <Flex flex="0" ><b>Return:</b></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.returnartwork} /></Flex>
<Flex flex="0"><Label>&nbsp;Artwork&nbsp;</Label></Flex>
    
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox"checked={this.props.jobinfo?.returndie}  /></Flex>
    <Flex flex="0"><Label>&nbsp;Die&nbsp;</Label></Flex>

    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.returnplates} /></Flex>
    <Flex flex="0"><Label>&nbsp;Plates&nbsp;</Label></Flex>

    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={this.props.jobinfo?.returnother} /></Flex>
    <Flex flex="0"><Label>&nbsp;Other&nbsp;</Label></Flex>
    <Flex flex="1"><Input type="text" defaultValue={this.props.jobinfo?.returnothertext}/></Flex>
    
</Flexed>
<Flexed>
    <Flex flex="0"><Label>Job Description </Label></Flex>
    <Flex ><Input textalign="left" type="text" defaultValue={this.props.jobinfo?.description} /></Flex>
</Flexed>
<Flexed padding="25px 0px 0px 0px">
    <Flex flex="0"><Label>Customer OK</Label></Flex>
    <Flex><Input type="text" /></Flex>
    <Flex flex="0"><Label>Date </Label></Flex>
    <Flex style={{borderBottom:"1px solid lightgrey",fontSize:".9rem"}}>&nbsp;&nbsp;<Moment format="MM/DD/YYYY">{this.props.jobinfo?.lastdate}</Moment></Flex>
</Flexed>

{
  this.props.jobinfo.delivered > "" && <Flexed border="1px solid grey" padding="10px 0px" margin="15px 0px 15px 0px">
    <Flex flex="3"></Flex>
  <Flex flex="1"><Label>Amount Delivered:<br />{this.props.jobinfo?.delivered}</Label></Flex>
  <Flex flex="1"><Label>Billing Charged:<br />{currency(this.props.jobinfo?.billingcost)} </Label></Flex>
  </Flexed>
}









  





    </Wrapper>

      </Flex>
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
