import styled from 'styled-components'
import { Label, Input } from '../../../Shared'
import { useState } from 'react'
import  { useAsyncDebounce } from 'react-table'

export const Wrap = styled.div`

padding:15px;
`;


const Search = ({filter,setFilter}) => {
  const [value, setValue] = useState(filter);

  const change = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 100)
  return (
    <span>Search:&nbsp;&nbsp;
    <Input type="text" value={value || ""}
  onChange={e => {
  setValue(e.target.value)
  change(e.target.value)
}  
  }

/>
 </span>
  )
}

export default Search




