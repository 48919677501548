import { useState,useEffect } from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox, Textarea, Wrapper } from '../../Shared'
import { useNavigate,useParams } from 'react-router-dom'
import axios from 'axios'
import { BsYelp } from 'react-icons/bs'

const EditCustomer = ({onCancel,reset,setReset}) => {

    const [newinfo,setNewinfo] = useState({})
    const [address,setAddress] = useState({})
    const [contact,SetContact] = useState({})
    const [customer,setCustomer] = useState({})
const nav = useNavigate()

const params = useParams()

useEffect(() => {
const getcustomer = () => {
    let r = {"id":params.id}
const y = axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getcustomerinfo', r)
.then(res => {setNewinfo(res.data)
    setAddress(res.data.address[0])
    SetContact(res.data.contacts[0])
})
.catch(err => console.log())
}
getcustomer()
},[])


const addNewCustomer = async(e) => {
    e.preventDefault()
    let ye = {
        id:newinfo._id,
        name:newinfo.name,
        website:newinfo.website,
        billingterms:newinfo.billingterms,
        taxexempt:newinfo.taxexempt,
        notes:newinfo.notes
    }
const y = axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/customers/update', ye)
.then(res => {setReset(!reset);onCancel()})
.catch(err => console.log())
}

console.log(newinfo)

  return (
    <Wrapper>
<Flexed>
    <Flex textAlign="center" fontSize="calc(1.6rem + .4vw)">Edit Customer</Flex>
</Flexed>
<Flexed margin="0px 0px 25px 0px">
    <Flex textAlign="right"></Flex> 
</Flexed>
    <form onSubmit={e => addNewCustomer(e)}>
    <Flexed gap="20px" margin="0px 0px 25px 0px">
        <Flex><Label>Company Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
      
    </Flexed>
      
    
   


    <Flexed gap="20px" margin="15px 0px 25px 0px">
    <Flex flex="2.5"><Label>Website</Label><Input type="text" value={newinfo?.website} onChange={e => setNewinfo({...newinfo,website:e.target.value})} /></Flex>
    <Flex><Label>Billing Terms</Label><Input type="text" value={newinfo?.billingterms} onChange={e => setNewinfo({...newinfo,billingterms:e.target.value})} /></Flex>
    <Flex><Label>Tax Exempt ID</Label><Input type="text" value={newinfo?.taxexempt} onChange={e => setNewinfo({...newinfo,taxexempt:e.target.value})} /></Flex>
    </Flexed>   
 



    <Flexed gap="20px">
    <Flex><Label>Notes</Label><br /><Textarea rows="5" value={newinfo?.notes} onChange={e => setNewinfo({...newinfo,notes:e.target.value})}></Textarea></Flex>

        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button onClick={onCancel} height="30px" style={{marginRight:"30px"}}>Cancel</Button><Button height="30px">Edit Customer</Button></Flex>
    </Flexed>   
    </form>
    </Wrapper>
  )
}

export default EditCustomer