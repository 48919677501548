import React, { useEffect,useState,useMemo } from 'react'
import styled from 'styled-components'
import { Button, Datepick, Flex, Flexed, Input, Label, Textarea, Wrapper,PhoneNumber, Selectbox, currency} from '../../Shared'
import axios from 'axios'
import Select from 'react-select'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import PrintComponent from '../../print/Jobticket';
import PackSlip from '../../print/Packinglist';
import {IoMdAddCircleOutline } from 'react-icons/io'
import {GrDocumentUpdate,GrClear } from 'react-icons/gr'
import { PatternFormat } from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import {useParams,useNavigate} from 'react-router-dom'
import { Checkbox } from '../../components/table/Checkbox'




const UpdateJob = () => {
const idOfJob = useParams();
console.log(idOfJob)
const nav = useNavigate()
    const [customers, setCustomers] = useState([])
    const [contacts, setContacts] = useState([])
    const [contact, setContact] = useState("")
    const [phone, setPhone] = useState("")
    const [customer, setCustomer] = useState({})
    const [customername, setCustomername] = useState("")
    const [addresslist, setAddresslist] = useState([])
    const [address, setAddress] = useState("")
   const [jobinfo, setJobinfo] = useState({})
   const [packinfo, setPackinfo] = useState({})
   const [packlist, setPacklist] = useState([])
      const [stamp, setStamp] = useState({})
const [currentstamp, setCurrentstamp] = useState({})
const [currentpress, setCurrentpress] = useState({})
const [ showprint, setShowprint] = useState(true)
const [ showpack, setShowpack] = useState(true)
const [ showaddress, setShowaddress] = useState(false)
const [ reset, setReset] = useState(false)
const [test,setTest] = useState([])

useEffect(() => {
    let t
    console.log(idOfJob.id)
    const getJob = async() => {
     t = await axios.get(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getjob/${idOfJob.id}`)
    .then(res => {console.log(res.data);setJobinfo({...res.data,recievedstock:new Date(res.data.recievedstock),recievedfilm:new Date(res.data.recievedfilm),needby:new Date(res.data.needby),lastdate:new Date(res.data.lastdate),customerjobid:res.data.customerjobid});setContact(res.data.customer.contacts[0]?.name || "");setContacts(res.data.customer?.contacts);setPhone(res.data?.mainphone);setTest(res.data.stamp);setAddress(res.data.address || "");setAddresslist(res.data.customer?.address);setCustomer(res.data?.customer);setCustomername(res.data?.customer.name);setPackinfo({...packinfo,shipdescription:res.data.description,shipdecustomer:res.data.customer.name,shippo:res.data.shippo ? res.data.shippo : res.data.po,shipto:res.data.shipto ? res.data.shipto : "Customer Pickup",packpo:res.data.jobid,returnartwork:res.data.returnartwork ? res.data.returnartwork : null,returndie:res.data.returndie ? res.data.returndie : null,returnplates:res.data.returnplates ? res.data.returnplates : null,returnother:res.data.returnother ? res.data.returnother : null,returnothertext:res.data.returnothertext ? res.data.returnothertext : null,shiplist:res.data.shiplist.length > 0 ? res.data.shiplist : null});setPacklist(res.data.shiplist)})
    .catch(err => console.log(err))
    /*.then(res => {setJobinfo(res.data)})
    .then(response => {
        setJobinfo({...jobinfo,needby:new Date(response.data.needby),recievedfilm:new Date(response.data.recievedfilm),recievedstock:new Date(response.data.recievedstock),lastdate:new Date(response.data.lastdate),cfpjobnum:response.data.jobid})
    })*/
    }
    getJob()

},[reset])

useEffect(() => {
if(packinfo.shipto > "" && packinfo.shipdescription > "" && packinfo.shippo > "" && packinfo.shipperpackage > "" && packinfo.shipnumpackages > "" && packinfo.shiptotal > ""){
    setShowpack(true)
}else{
    setShowpack(false)
}
},[packinfo])


useEffect(() => {
    let t 
    const getJob = async() => {
        t = await axios.get(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getjob/${idOfJob.id}`)
       .then(res => {setTest(res.data.stamp);})
       .catch(err => console.log(err))
       /*.then(res => {setJobinfo(res.data)})
       .then(response => {
           setJobinfo({...jobinfo,needby:new Date(response.data.needby),recievedfilm:new Date(response.data.recievedfilm),recievedstock:new Date(response.data.recievedstock),lastdate:new Date(response.data.lastdate),cfpjobnum:response.data.jobid})
       })*/
       }
       getJob()
},[reset])

console.log(jobinfo)

useEffect(() => {

const getCustomers = async() => {
const t = await axios.get('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getall')
.then(res => {setCustomers(res.data)})
.catch(err => console.log(err))
}
getCustomers()
},[])




const setcustomer = async(e) => {
    const name = {name:e.target.value}
    const t = await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getCustomer',name)
    .then(res => {console.log(res.data);
        if(res.data.length === 0){
            setCustomer({});setContacts([]);setContact("");setPhone("");setCustomername(name.name);setAddress("");setAddresslist([])

        }else{
            console.log(res.data[0]);
            setCustomer(res.data[0]);
            setContacts(res.data[0]?.contacts);
            setContact(res.data[0].contacts[0].name);
            setPhone(PhoneNumber(res.data[0]?.mainphone));
            setCustomername(res.data[0]?.name);
            if(res.data[0].address.length > 0){
                setAddresslist(res.data[0].address);
                setAddress(res.data[0].address[0].address1+" " +res.data[0].address[0].address2+" "+res.data[0].address[0].city+", "+res.data[0].address[0].state+" "+res.data[0].address[0].zip);
            }
           
        }
        
    })    
    .catch(err => console.log(err))
}



const submit= async(e) => {
    e.preventDefault()
    const lat = {
        id:idOfJob.id,
        jobinfo:jobinfo,
        stamp:test,
        mainphone:phone,
        contact:contact,
        customername:customername,
        address:address,
        customerid:customer._id,
     }
    

    axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/jobs/updatejob',lat)
    // axios.post(' http://localhost:5007/jobs/updatejob',lat)
    .then(res => {console.log(res.data);setJobinfo(res.data);toast.success(`Your job has been updated.`,{autoClose: 6000})})
    .catch(err => console.log(err))
    console.log(jobinfo)

    setReset(!reset)
}


const closejob = () => {
    const lat = {
        "id":idOfJob.id
    }
   axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/jobs/close/job',lat)
    //axios.post('http://localhost:5007/jobs/close/job',lat)
    .then(res => {toast.success('This job has been closed');setReset(!reset)})
    .catch(err => console.log(err))
}
console.log(addresslist)
   

const clear = () => {
    setCurrentstamp({...currentstamp,stamp:"",process:"",plates:"",passes:"",on:"",areal:"",areaw:"",sheetl:"",sheetw:"",sheets:"",overs:"",overpercent:"",id:"",overpercentquantity:""})
}

const addstamp = () => {
    if(currentstamp.process === ""){
 return toast.error('Please Select A Process')
    }
    if(currentstamp.overs === "Overs"){
        if(currentstamp.overpercent === ""){
            return toast.error('Please Select A Percentage Of Overs')
        }
           }
if(currentstamp.overs === ""){  
    return toast.error('Please Select Overs')
}   
if(currentstamp.id){
const objIndex = test.findIndex((obj => obj.id === currentstamp.id));
test[objIndex] = currentstamp
setTest(test)
}else{
    currentstamp.id = uuidv4()
    setTest(test => [...test,currentstamp]) 
}
clear()   
}

const changepercent = (e) => {
let r = 0
r = (e.target.value/100) * currentstamp.sheets;
let t = parseInt(currentstamp.sheets) + parseInt(r)
setCurrentstamp({...currentstamp,overpercentquantity:t,overpercent:e.target.value})
}

const changepresspercent = (e) => {
    let r = 0
    r = (e.target.value/100) * currentpress.sheets;
    let t = parseInt(currentpress.sheets) + parseInt(r)
    setCurrentstamp({...currentpress,overpercentquantity:t,overpercent:e.target.value})
    }



const savedelivery = (e) => {
    e.preventDefault()
    let r = 
       { "shipnumpackages":packinfo.shipnumpackages,
       "shipperpackages":packinfo.shipperpackages,
       "shiptotal":packinfo.shiptotal,
       "shipdescription":packinfo.shipdescription
    }
    packlist.push(r)
    const lat = {
        "id":idOfJob.id,
       "shipto":packinfo.shipto,
       "shippo":packinfo.shippo,
       "shipdescription":packinfo.shipdescription,
      "shiplist":packlist,
       "partial":packinfo.shiptotal     
    }
 axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/jobs/update/delivery',lat)
    //axios.post('http://localhost:5007/jobs/update/delivery',lat)
    .then(res => {toast.success('Delivery Information Saved');setReset(!reset);setPackinfo({...packinfo,shipnumpackages:"",shipperpackages:"",shiptotal:"",shipdescription:""})})
    .catch(err => console.log(err))
   

}
console.log(packinfo)


//console.log(test)
console.log(packinfo)

const savetotal = (e) => {
    const lat = {
        id:idOfJob.id,
        jobinfo:jobinfo,
       
       
     }
    axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/jobs/update/status',lat)
    //axios.post(' http://localhost:5007/jobs/updatejob',lat)
   .then(res => {console.log(res.data);setReset(!reset);toast.success(`Information Saved.`,{autoClose: 6000})})
   .catch(err => console.log(err))
}

  return (
    <>
    
    <Wrapper style={{paddingBottom:"0",marginBottom:"0"}}>
  
        <form onSubmit={submit} style={{position:"relative",width:"100%"}}>
      {jobinfo.closed &&  <div style={{position:"absolute",top:"0",left:"0",height:"100%",width:"100%",background:"rgb(0,0,0,.1)",zIndex:"20",}}>
         <div style={{color:"rgb(0,0,0,.3)",height:"100%",width:"100%",transform:"rotate(-45deg)",display:"flex",justifyContent:"center",alignItems:"center",fontSize:"7rem"}}>Closed</div> 
   
        </div>}
    <Flexed>
        <Flex textAlign="center" fontSize="calc(1.6rem + .4vw)">CFP Job Ticket</Flex>
    </Flexed>
    <Flexed margin="0px 0px 25px 0px">
        <Flex textAlign="right"><Label>CFP Job#&nbsp;&nbsp;</Label><Input type="text" style={{fontWeight:"bold",fontSize:"calc(1rem + .4vw)",outline:"none",border:"0",borderBottom:"1px solid grey"}} value={jobinfo?.jobid} width="10%" onChange={e => setJobinfo({...jobinfo,jobid:e.target.value})} /></Flex> 
    </Flexed>

    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0"> <Label>Customer</Label></Flex>
<Flex flex="1"><Input width="100%" list="customerlist" value={customername} name="" id="" onChange={e => setcustomer(e)}/>
<datalist id="customerlist">
  {customers?.map((customer,index) => <option key={index} value={customer.name} />)}
</datalist></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Received Stock</Label></Flex>
<Flex flex="0" > {jobinfo?.recievedstock > null ?<Datepick  selected={jobinfo?.recievedstock} onChange={date => setJobinfo({...jobinfo,recievedstock:date})}/>:<Input width="auto" type="text" />} </Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
<Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ticket</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" value={jobinfo?.ticket} onChange={e => setJobinfo({...jobinfo,ticket:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>

    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0" textAlign="right"> <Label>Contact&nbsp;&nbsp;&nbsp;</Label></Flex>
<Flex flex="1"><Input width="100%" list="contactlist" name="" id="" value={contact} onChange={e => setContact(e.target.value)}/>
<datalist id="contactlist">
  {contacts?.map((contact,index) => <option key={index} value={contact.name} />)}
</datalist></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Received Film</Label></Flex>
<Flex flex="0">{jobinfo?.recievedfilm > 0 ?<Datepick  selected={jobinfo?.recievedfilm} onChange={date => setJobinfo({...jobinfo,recievedfilm:date})}/>:<Input width="auto" type="text" />} </Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
                <Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Finish</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" value={jobinfo?.finish} onChange={e => setJobinfo({...jobinfo,finish:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>
    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0" textAlign="right"> <Label>Phone#&nbsp;&nbsp;&nbsp;</Label></Flex>
<Flex flex="1"><PatternFormat value={phone} format="(###) ### - ####" onChange={e => setPhone(e.target.value)} /></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Need By</Label></Flex>
<Flex flex="0">{jobinfo?.needby > 0 ?<Datepick  selected={jobinfo?.needby} onChange={date => setJobinfo({...jobinfo,needby:date})}/>:<Input width="auto" type="text" />}  </Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
                <Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C/B/C</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" value={jobinfo?.cbc} onChange={e => setJobinfo({...jobinfo,cbc:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>
<hr />
<Flexed margin="15px 0px 10px 0px">
<Flex><Textarea minRows="5" value={jobinfo?.text1} onChange={e => setJobinfo({...jobinfo,text1:e.target.value})}></Textarea></Flex>
<Flex><Textarea minRows="5" value={jobinfo?.text2} onChange={e => setJobinfo({...jobinfo,text2:e.target.value})}></Textarea></Flex>
</Flexed>
<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
<Flexed>

    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Foil Stamp & Emboss</Flex>
</Flexed>
<Flexed >

<Flex flex="1.3">
    <Selectbox value={currentstamp.process} onChange={e => setCurrentstamp({...currentstamp,process:e.target.value})}>
    <option value="">Process</option>  
    <option value="Stamp">Stamp</option>
    <option value="Emboss">Emboss</option>
      

    </Selectbox>
</Flex>
<Flex flex="0">{currentstamp.process}</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.stampcount} width="100%" onChange={e => setCurrentstamp({...currentstamp,stampcount:e.target.value})}/></Flex>

<Flex flex=".1"></Flex>
<Flex flex="0">Plates</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.plates} width="100%" onChange={e => setCurrentstamp({...currentstamp,plates:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Passes</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.passes} width="100%" onChange={e => setCurrentstamp({...currentstamp,passes:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">On</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.on} width="100%" onChange={e => setCurrentstamp({...currentstamp,on:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Area</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.areal} width="100%" onChange={e => setCurrentstamp({...currentstamp,areal:e.target.value})}/></Flex>
<Flex flex="0">X</Flex>
<Flex  flex="1"><Input value={currentstamp.areaw} type="text" width="100%" onChange={e => setCurrentstamp({...currentstamp,areaw:e.target.value})}/></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>Sheet Size</Label></Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.sheetl} width="100%" onChange={e => setCurrentstamp({...currentstamp,sheetl:e.target.value})}/></Flex>
<Flex flex="0">X</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.sheetw} width="100%" onChange={e => setCurrentstamp({...currentstamp,sheetw:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Sheets</Flex>
<Flex  flex="1"><Input type="text" width="100%" value={currentstamp.sheets} onChange={e => setCurrentstamp({...currentstamp,sheets:e.target.value})}/></Flex>
<Flex flex="2"></Flex>

</Flexed>
<Flexed>
<Flex flex="2">{/*<Label>Plates are</Label>*/}</Flex>


<Flex flex=".1"></Flex>
<Flex><Selectbox placeholder="No" value={currentstamp.overs} onFocus={e => setCurrentstamp({...currentstamp,overs:"",overpercent:""})} onChange={e => setCurrentstamp({...currentstamp,overs:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    <Flex flex=".1"></Flex>
   {currentstamp?.overs === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={currentstamp.overpercent} width="100%"  onChange={e => changepercent(e)}/></Flex>
</div> }<Flex flex=".1"></Flex>
<Flex flex="0" padding="0px 10px"  color="yellow" fontSize="calc(1.2rem + .4vw)">{test?.length < 4 && <GrDocumentUpdate onClick={addstamp} />}</Flex>
<Flex flex="0" padding="0px 10px"  color="yellow" fontSize="calc(1.2rem + .4vw)">{test?.length < 4 && <GrClear onClick={clear} />}</Flex>
</Flexed>

{
    test?.length > 0 && <Flexed style={{background:"var(--plsGreen)",borderBottom:"1px solid grey"}}>
    <Flex>Process</Flex>
    <Flex>Stamp</Flex>
    <Flex>Plates</Flex>
    <Flex>Passes</Flex>
    <Flex>On</Flex>
    <Flex>Area Size</Flex>
    <Flex>Sheet Size</Flex>
    <Flex>Sheets</Flex>
    <Flex flex="2">Overs</Flex>
  </Flexed>
}
    {test?.map((item,index) => (
  
        <Flexed key={index} style={{borderBottom:"1px solid lightgrey"}} onClick={ e => setCurrentstamp(item)}>
    <Flex>{item.process}</Flex>
    <Flex>{item.stampcount}</Flex>
    <Flex>{item.plates}</Flex>
    <Flex>{item.passes}</Flex>
    <Flex>{item.on}</Flex>
    <Flex>{item.areal+" X "+item.areaw}</Flex>
    <Flex>{item.sheetl+" X "+item.sheetw}</Flex>
    <Flex>{item?.overpercent > 0 ? item.overpercentquantity+" Incl -" : item?.sheets}</Flex>
    <Flex flex="2">{item?.overpercent > 0 ? item?.overpercent+"% Overs" : item?.overs}</Flex>
  </Flexed>
    ))
}


<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>

<div style={{position:"relative"}}>
<Flexed>
<Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Die Room</Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>One Cutting Die</Label></Flex>
<Flex  flex="1"><Input type="text" value={jobinfo?.cuttingdie}  onChange={e => setJobinfo({...jobinfo,cuttingdie:e.target.value})} /></Flex>
<Flex flex="0"><Label>On</Label></Flex>
<Flex  flex="5"><Input type="text"  value={jobinfo?.cuttingon}  onChange={e => setJobinfo({...jobinfo,cuttingon:e.target.value})}/></Flex>
<Flex flex=".5"></Flex>
<Flex flex="0"><Label>Die #</Label></Flex>
<Flex  flex="1"><Input type="text" value={jobinfo?.cuttingdie1}  onChange={e => setJobinfo({...jobinfo,cuttingdie1:e.target.value})}/></Flex>
<Flex flex="2.5"><Selectbox value={jobinfo?.cuttingdiefurnished} onChange={e => setJobinfo({...jobinfo,cuttingdiefurnished:e.target.value})}>
    
<option value="">Select Die Status</option>
<option value="Have Die">Have Die</option>
    <option value="Die Furnished">Die Furnished</option>
    <option value="House Die">House Die</option>
    <option value="Die Re-Rule">Die Re-Rule</option>
    </Selectbox></Flex>
    <Flex flex="0"><Label>Die #</Label> </Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.cuttingdie2}  onChange={e => setJobinfo({...jobinfo,cuttingdie2:e.target.value})}/></Flex>
</Flexed>
</div>
    <hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>

   
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Press Room</Flex>
</Flexed>

<Flexed >
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pressdiecut} onChange={e => setJobinfo({...jobinfo,pressdiecut:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Die Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pressdiecut2} onChange={e => setJobinfo({...jobinfo,pressdiecut2:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>2nd Die Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.presskiss} onChange={e => setJobinfo({...jobinfo,presskiss:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Kiss Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pressperf} onChange={e => setJobinfo({...jobinfo,pressperf:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Perforate</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pressscore} onChange={e => setJobinfo({...jobinfo,pressscore:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Score</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pressstrip} onChange={e => setJobinfo({...jobinfo,pressstrip:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Strip</Label></Flex>
</Flexed>
<Flexed >
<Flex ><Input type="text" value={jobinfo?.presssheetscut} onChange={e => setJobinfo({...jobinfo,presssheetscut:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Sheets Cut</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text"value={jobinfo?.presssheetstimes} onChange={e => setJobinfo({...jobinfo,presssheetstimes:e.target.value})} /></Flex>
    <Flex flex="0"><Label>Times for</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text" value={jobinfo?.presssheetsimpressions} onChange={e => setJobinfo({...jobinfo,presssheetsimpressions:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Impressions</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text" value={jobinfo?.presssheetson} onChange={e => setJobinfo({...jobinfo,presssheetson:e.target.value})}/></Flex>
    <Flex flex="0"><Label>On</Label></Flex>
    <Flex flex=".2"></Flex>
    <Flex ><Input type="text" value={jobinfo?.presssheetsl} onChange={e => setJobinfo({...jobinfo,presssheetsl:e.target.value})}/></Flex>
    <Flex flex="0"><Label>X</Label></Flex>
    <Flex ><Input type="text" value={jobinfo?.presssheetsw} onChange={e => setJobinfo({...jobinfo,presssheetsw:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Sheet Size</Label></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Input type="checkbox" selected={jobinfo?.necessary} onChange={e => setJobinfo({...jobinfo,necessary:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Necessary</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo?.complete} onChange={e => setJobinfo({...jobinfo,complete:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Complete</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo?.repeat} onChange={e => setJobinfo({...jobinfo,repeat:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Repeat</Label></Flex>
    <Flex flex="3"></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>Quantity Sheets</Label></Flex>
    <Flex ><Input type="text" value={jobinfo?.presssheets} onChange={e => setJobinfo({...jobinfo,presssheets:e.target.value})}/></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Quantity Pieces</Label></Flex>
    <Flex ><Input type="text" value={jobinfo?.presspieces} onChange={e => setJobinfo({...jobinfo,presspieces:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex><Selectbox  value={jobinfo?.overs} onFocus={e => setJobinfo({...jobinfo,pressoverpercent:"",overs:""})} onChange={e => setJobinfo({...jobinfo,overs:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    {jobinfo?.overs === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={jobinfo?.pressoverpercent} width="100%"  onChange={e => setJobinfo({...jobinfo,pressoverpercent:e.target.value})}/></Flex>
</div> }
</Flexed>


<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
  
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Glue Room</Flex>
</Flexed>

<Flexed >
    <Flex flex="0"><Label>Glue</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.glue} onChange={e => setJobinfo({...jobinfo,glue:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Pieces with</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.gluepieces1} onChange={e => setJobinfo({...jobinfo,gluepieces1:e.target.value})}/></Flex>
    <Flex flex="0"><Label>/</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.gluepieces2} onChange={e => setJobinfo({...jobinfo,gluepieces2:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Flaps</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Final Fold</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.gluefinalfold} onChange={e => setJobinfo({...jobinfo,gluefinalfold:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Pieces</Label></Flex>
</Flexed>
<Flexed >
<Flex flex="0"><Input type="checkbox" checked={jobinfo?.gluekraftwrap} onChange={e => setJobinfo({...jobinfo,gluekraftwrap:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Kraft Wrap In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,gluekraftquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.glueboxin} onChange={e => setJobinfo({...jobinfo,glueboxin:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Box In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,glueboxquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.glueshrinkwrap} onChange={e => setJobinfo({...jobinfo,glueshrinkwrap:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Shrink Wrap In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,glueshrinkquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.gluerubberbandin} onChange={e => setJobinfo({...jobinfo,gluerubberbandin:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Rubber Band In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,gluerubberquantity:e.target.value})}/></Flex>
</Flexed>
<Flexed>
    <Flex flex="5"></Flex>
<Flex><Selectbox  value={jobinfo?.glueovers} onFocus={e => setJobinfo({...jobinfo,glueoverpercent:"",glueovers:""})} onChange={e => setJobinfo({...jobinfo,glueovers:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    {jobinfo?.glueovers === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={jobinfo?.glueoverpercent} width="100%"  onChange={e => setJobinfo({...jobinfo,glueoverpercent:e.target.value})}/></Flex>
</div> }
</Flexed>

<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
  
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Shipping Instructions</Flex>
</Flexed>



<Flexed >
<Flex flex="0"><Input type="checkbox" checked={jobinfo?.deliver} onChange={e => setJobinfo({...jobinfo,deliver:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Deliver</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.cod} onChange={e => setJobinfo({...jobinfo,cod:e.target.checked})} /></Flex>
    <Flex flex="0"><Label>C.O.D</Label></Flex>
    <Flex><Input type="text" value={jobinfo?.codtext} onChange={e => setJobinfo({...jobinfo,codtext:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.pickup} onChange={e => setJobinfo({...jobinfo,pickup:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Customer Pick-up</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.otherpickup} onChange={e => setJobinfo({...jobinfo,otherpickup:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Other</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo?.ups} onChange={e => setJobinfo({...jobinfo,ups:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>UPS</Label></Flex>
    <Flex flex=".5"></Flex>
</Flexed>

{jobinfo?.deliver || jobinfo.cod ?
<Flexed>
    <Flex flex="1" padding="10px 0px"><Input width="100%" list="addresslistt" value={addresslist.length > 0 ? address : ""} name="" id="" onChange={e => setAddress(e.target.value)}/>
<datalist id="addresslistt">
  {addresslist?.map((addy,index) => <option key={index} value={addy.address1 || ""+" "+addy.address2+" "+addy.city+", "+addy.state+" "+addy.zip} />)}
</datalist>
</Flex>
</Flexed>:""
}
<Flexed >
    <Flex flex="0"><Label>P.O.#</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.po} onChange={e => setJobinfo({...jobinfo,po:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Customer Job #</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.customerjobid} onChange={e => setJobinfo({...jobinfo,customerjobid:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Bid #</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.bid} onChange={e => setJobinfo({...jobinfo,bid:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>CFP Job #</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.jobid} onChange={e => setJobinfo({...jobinfo,jobid:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
</Flexed>
<Flexed>
    <Flex flex="0">Return:</Flex>
<Flex flex="0"><Label>Artwork</Label></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo.returnartwork} onChange={e => setJobinfo({...jobinfo,returnartwork:e.target.checked})} /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Die</Label></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo.returndie} onChange={e => setJobinfo({...jobinfo,returndie:e.target.checked})} /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Plates</Label></Flex>
    <Flex flex="0"><Input type="checkbox"checked={jobinfo.returnplates} onChange={e => setJobinfo({...jobinfo,returnplates:e.target.checked})}  /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Other</Label></Flex>
    <Flex flex="0"><Input type="checkbox" checked={jobinfo.returnother} onChange={e => setJobinfo({...jobinfo,returnother:e.target.checked})}  /></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo?.returnothertext} onChange={e => setJobinfo({...jobinfo,returnothertext:e.target.value})}/></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="2"></Flex>
</Flexed>
<Flexed>
    <Flex flex="0"><Label>Job Description </Label></Flex>
    <Flex><Input type="text" value={jobinfo?.description} onChange={e => setJobinfo({...jobinfo,description:e.target.value})}/></Flex>
</Flexed>
<Flexed padding="25px 0px 0px 0px">
    <Flex flex="0"><Label>Customer OK</Label></Flex>
    <Flex><Input type="text" /></Flex>
    <Flex flex="0"><Label>Date </Label></Flex>
    <Flex>{jobinfo?.lastdate > 0 ?<Datepick  selected={jobinfo?.lastdate} onChange={date => setJobinfo({...jobinfo,lastdate:date})}/>:<Input width="auto" type="text" />} </Flex>
</Flexed>
<Flexed><Flex><Button type="submit">Update Job</Button></Flex><Flex >{showprint && jobinfo.closed === false && <PrintComponent style={{position:"absolute",right:"0"}} jobinfo={jobinfo}   />}</Flex></Flexed>

</form>
<br /><br />
<hr />
<Wrapper padding="15px 0px ">
<Flexed ><Flex textAlign="center"><h3>Invoice Details</h3></Flex></Flexed>

<Flexed>
    <Flex flex="3"><Label>Total Quantity:<br /><Input type="text" value={jobinfo.totalquantity} onChange={e => setJobinfo({...jobinfo,totalquantity:e.target.value})}/></Label></Flex>
    <Flex><Label>Total $:<br /><Input type="text" value={jobinfo.totaltotal} onChange={e => setJobinfo({...jobinfo,totaltotal:e.target.value})}/></Label></Flex>
    </Flexed>
{jobinfo.totalquantity > "" && jobinfo.totaltotal > "" && <Flexed ><Flex flex="3"></Flex><Flex textAlign="right" ><Button onClick={e => savetotal(e)} >Save</Button></Flex>{showprint && jobinfo.closed === true && <Flex flex="0" margin="0px 0px 0px 30px">{/*<PrintComponent style={{position:"absolute",right:"0"}} jobinfo={jobinfo}   />*/}</Flex>}</Flexed>}
</Wrapper>
<hr />
<Wrapper padding="15px 0px ">

<Flexed ><Flex textAlign="center"><h3>Delivery Information</h3></Flex></Flexed>

<Flexed>
    <Flex flex="3"><Label>Ship To:</Label><br />

<Selectbox  value={packinfo.shipto} onChange={e => setPackinfo({...packinfo,shipto:e.target.value})} required>
<option value="" selected disabled>Select Delivery Method</option>
<option value="Customer Pickup">Customer Pickup</option>
    {customer.address?.map(item =>  <option value={`${item.address1}\n${item.city},${item.state} ${item.zip}`}>{item.address1} {item.address2} {item.city},{item.state} {item.zip}</option>)}
</Selectbox>
</Flex>
    <Flex><Label>PO/ Job:<br /><Input type="text" value={packinfo.shippo} onChange={e => setPackinfo({...packinfo,shippo:e.target.value})}/></Label></Flex>
    </Flexed>
    <Flexed>
    <Flex><Label>Description:<br /><Input type="text" value={packinfo.shipdescription} onChange={e => setPackinfo({...packinfo,shipdescription:e.target.value})}/></Label></Flex>
    </Flexed>
 
<form>
    <Flexed margin="25px 0px 0px 0px">
    <Flex><Label># of Packages:<br /><Input type="text" value={packinfo.shipnumpackages} onChange={e => setPackinfo({...packinfo,shipnumpackages:e.target.value})}/></Label></Flex>
    <Flex><Label>Per Package:<br /><Input type="text" value={packinfo.shipperpackages} onChange={e => setPackinfo({...packinfo,shipperpackages:e.target.value})} /></Label></Flex>
    <Flex><Label>Total:<br /><Input type="text" value={packinfo.shiptotal} onChange={e => setPackinfo({...packinfo,shiptotal:e.target.value})}/></Label></Flex>
    
    <span style={{margin:"20px 0px 0px 30px"}}><Button onClick={e => savedelivery(e)}>Save Delivery Info</Button></span>
</Flexed >

</form>
<Flexed ><Flex flex="0"  padding="25px 0px 0px 0px" style={{whiteSpace:"nowrap"}}>Partial Delivery</Flex><Flex flex="0" padding="25px 0px 0px 0px" ><Input type="checkbox" checked={packinfo.partial} onChange={e => setPackinfo({...packinfo,partial:e.target.checked})}  /></Flex>



<Flex >

    <PackSlip jobinfo={jobinfo} packinfo={packinfo} today={new Date()} customer={customer} addresslist={addresslist} address={address} jobinfo={jobinfo} /></Flex></Flexed>

   {
    packinfo.shiplist?.length > 0 &&
<div><u>Current Delivery Lines</u><br /><Flexed style={{borderBottom:"1px solid black"}}><Flex flex="2">Description</Flex><Flex>Packages</Flex><Flex>Per Package</Flex><Flex>Total</Flex></Flexed>
{packinfo.shiplist.map(item => {
return <Flexed><Flex flex="2">{item.shipdescription}</Flex><Flex>{item.shipnumpackages}</Flex><Flex>{item.shipperpackages}</Flex><Flex>{item.shiptotal}</Flex></Flexed>
})}
</div>
    
   }
      


</Wrapper>
<br /><Button onClick={closejob} >Close Job</Button><br /><br /><br /><br /><br /><br />
</Wrapper>


</>
  )
}

export default UpdateJob