import { AddIcon, Button, Datepick, Flex, Flexed, Input, Label, Selectbox, Wrapper, currency } from '../../Shared'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import AsyncSelect from 'react-select/async';
import { useNavigate,useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';
import PrintComponent from '../../print/PoSheet';
import {MdDeleteOutline } from 'react-icons/md'


const Newpo = () => {
const [vendorlist, setVendorlist] = useState([])
const [itemslist, setItemslist] = useState([])
const [vendor, setVendor] = useState({})
const [poinfo, setPoinfo] = useState({})
const [currentitem, setCurrentitem] = useState({})
const [currentitems, setCurrentitems] = useState([])
const [duedate, setDuedate] = useState()
const [reset, setReset] = useState(false)
const [listreset, setListreset] = useState(false)

const [test,setTest] = useState([])


const param = useParams()
const nav = useNavigate()

const CATS = ["Dies","Foils","Supplies","Maintainence"]

useEffect(() => {
  const getitems = async() =>{
    let y
  await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getallproducts')
  .then(res => {console.log(res.data);setItemslist(res.data)})              
  .catch(err => console.log())
  }
  getitems() 
},[listreset])



useEffect(() => {
const getvendors = async() =>{
  let y
await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getall')
.then(res => setVendorlist(res.data))              
.catch(err => console.log())
}

const getPoinfo = async() => {
  await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/getpoinfo',{id:param.id})
  .then(res => {
    console.log(res.data);
    setPoinfo(res.data);
    setDuedate(new Date(res.data.duedate))
    setVendor(res.data.vendor)
    setCurrentitems(res.data.items)
  })
  .catch((err) => console.log(err))
  }
  getPoinfo()

getvendors()

},[reset])

const defaultcurrent = {
  quantity:"",
  name:"",
  orderunit:"",
  unitprice:"",
  priceunit:"",
  extprice:null,
  jobnum:"",
  quote:"",

}
useEffect(() => {
 const t = parseFloat(currentitem?.quantity) * parseFloat(currentitem?.unitprice)
  setCurrentitem({...currentitem,extprice:t})
  console.log(t)
},[currentitem.quantity,currentitem.unitprice])


const deleteitem = (id) => {
  const newItems = currentitems.filter(item => item.id !== id);

  setCurrentitems(newItems);
  let y = setCurrentitem(defaultcurrent)
 }

const setcustomer = async(value) => {
  const name = {name:value}


  const t = await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getvendorbyname',name)
  .then(res => {console.log(res.data);
      if(res.data[0]){
       
        setVendor(res.data[0]);
      }else{
     setVendor({name:name.name})

      }
      
  })    
  .catch(err => console.log(err))
  
}


const settheitem = async(value) => {
  const name = {name:value}
  const t = await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getproduct',name)
  .then(res => {console.log(res.data);
      if(res.data[0]){
       
        setCurrentitem(prev => {return {...prev, ...res.data[0]}});
      }else{
        setCurrentitem({name:name.name})

      }
      
  })    
  .catch(err => console.log(err))
}

/*const addstamp = () => {
  if(currentstamp.process === ""){
return toast.error('Please Select A Process')
  }
  if(currentstamp.overs === "Overs"){
      if(currentstamp.overpercent === ""){
          return toast.error('Please Select A Percentage Of Overs')
      }
         }
if(currentstamp.overs === ""){  
  return toast.error('Please Select Overs')
}   
if(currentstamp.id){
const objIndex = test.findIndex((obj => obj.id === currentstamp.id));
test[objIndex] = currentstamp
setTest(test)
}else{
  currentstamp.id = uuidv4()
  setTest(test => [...test,currentstamp]) 
}
clear()   
}
*/

const clear = () => {
  setCurrentitem(defaultcurrent)
}

const additemtolist = () => {
  if(currentitem.id){
    const objIndex = currentitems.findIndex((obj => obj.id === currentitem.id));
    currentitems[objIndex] = currentitem
    setCurrentitems(currentitems)
    }else{
        currentitem.id = uuidv4()
        setCurrentitems(currentitems => [...currentitems,currentitem]) 
    }
    clear()
}

console.log(currentitems)
const getproduct = async(e) => {
/*
  const name = {name:value}

  const t = await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getproduct',name)
  .then(res => {console.log(res.data);
      if(res.data[0]){
       
        setVendor(res.data[0]);
      }else{
     setVendor({name:name.name})

      }
      
  })    
  .catch(err => console.log(err))
  */
}

console.log(currentitem)

const updateProduct = async(feild,value) => {
 
  let y
  const yey = async() => {
    y = {
      id: currentitem._id,
      feild,
      value
   }
    await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/products/updateproduct',y)
    .then(res => {toast.success('Item updated');setReset(!reset)})
    .catch()
    
  console.log(y)
  }
 
yey()
  }


  const addtodatabase = async() => {


    const y = {
      name:currentitem.name,
  orderunit:currentitem.orderunit,
  unitprice:currentitem.unitprice,
  priceunit:currentitem.priceunit,
    }
   await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/products/addproduct',y)
   // await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/products/addproduct',y)
      .then(res => {setCurrentitem(res.data);setReset(!reset);toast.success('Item Saved')})
      .catch(err => console.log(err))
    
  }

 const submit= async(e) =>{
  e.preventDefault()
  const lat = {
    id:poinfo._id,
      items:currentitems,
      vendor:vendor._id,
      duedate:poinfo.duedate
   }
  

   axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/updatepo',lat)
  .then(res => {console.log(res.data);toast.success(`Your PO has been updated.`,{autoClose: 6000});setReset(!reset)})
  .catch(err => console.log(err))

 }
console.log(currentitem)
  return (
    <Wrapper padding="0">
    <form onSubmit={submit}>
<Flexed>
    <Flex textAlign="center" fontSize="calc(1.6rem + .4vw)" padding="30px 0px">CFP Purchase Order# {poinfo?.poid}</Flex>
</Flexed>
<Flexed margin="30px 0px 10px 0px" >

<Flex flex="1.5"  >{!vendor?._id && <AddIcon top="0" right="10px" onClick={ () => nav(`/vendors/new?name=${vendor?.name}`)} />}<Label>Vendor</Label><br /><Input style={{backgroundColor:!vendor?._id ? "rgb(255,255,0,.4)" : "white"}} width="100%" list="customerlist" value={vendor?.name} name="" id="" onChange={e => setcustomer(e.target.value)} required/>
<datalist id="customerlist">
  {vendorlist?.map((customer,index) => <option key={index} value={customer?.name} />)}
</datalist> </Flex>
<Flex flex=".5"><Label>Due Date</Label><Datepick type="text" selected={duedate} onChange={date => setDuedate(date)}/></Flex>
<Flex ><Label>Invoice</Label><Input value={poinfo?.invoice} type="text" onChange={e => setPoinfo({...poinfo,invoice:e.target.value})}/></Flex>

</Flexed>
<hr style={{margin:"15px 0px",border:"2px solid grey"}}/>


<Flexed>

<Flex ><Label>Item</Label><br /><Input style={{backgroundColor:!currentitem?._id ? "rgb(255,255,0,.4)" : "white"}} width="100%" list="itemlist" value={currentitem?.name} name="" id="" onChange={e => settheitem(e.target.value)} />
<datalist id="itemlist">
  {itemslist?.map((item,index) => <option key={index} value={item?.name} />)}
</datalist> </Flex>
<Flex flex=".2"></Flex>
</Flexed>
<Flexed margin="0px 0px 50px 0px">
  <Flex><Label>Quantity</Label><Input type="text" value={currentitem?.quantity} onChange={e => setCurrentitem({...currentitem,quantity:e.target.value})}/></Flex>
  <Flex><Label>Order Unit</Label><br /><Selectbox style={{backgroundColor:!currentitem?._id ? "rgb(255,255,0,.4)" : "white"}} value={currentitem?.orderunit} onChange={e => setCurrentitem({...currentitem,orderunit:e.target.value})}>
    <option  value=""  >Select Order Units</option>
    <option  value="Each">Each</option>
    <option  value="Foot">Foot</option>
    <option  value="Roll">Roll</option>
    <option  value="Case">Case</option>
    <option  value="Square Feet">Square Feet</option>
    <option  value="Yard">Yard</option>
    </Selectbox>
    {currentitem._id &&
   <Button type="button" style={{position:"absolute",bottom:"-30px",right:"5px"}} padding="3px 10px">Update</Button>}
    </Flex>
  
  <Flex><Label>Unit Price</Label><br /><Input type="text" style={{backgroundColor:!currentitem?._id ? "rgb(255,255,0,.4)" : "white"}} value={currentitem?.unitprice} onChange={e => setCurrentitem({...currentitem,unitprice:e.target.value})}/>
  {currentitem._id &&
  <Button type="button" style={{position:"absolute",bottom:"-30px",right:"5px"}} padding="3px 10px" onClick={e => updateProduct('unitprice',currentitem?.unitprice)} >Update</Button>}
  </Flex>
 
  <Flex><Label>Price Unit</Label><br /><Selectbox style={{backgroundColor:!currentitem?._id ? "rgb(255,255,0,.4)" : "white"}} value={currentitem?.priceunit} onChange={e => setCurrentitem({...currentitem,priceunit:e.target.value})}>
    <option  value="" selected disabled>Select Price Units</option>
    <option  value="Each">Each</option>
    <option  value="Foot">Foot</option>
    <option  value="Roll">Roll</option>
    <option  value="Case">Case</option>
    <option  value="Square Feet">Square Feet</option>
    <option  value="Yard">Yard</option>
    </Selectbox>{currentitem._id && <Button type="button" style={{position:"absolute",bottom:"-30px",right:"5px"}} padding="3px 10px">Update</Button>}
  </Flex>
  <Flex><Label>Ext Price</Label><br /><Input type="text" value={isNaN(currentitem?.extprice) ? "" : currency(currentitem?.extprice) }/> </Flex>
</Flexed>
<Flexed >

  <Flex ><Label>Job Num</Label><Input type="text" value={currentitem.jobnum} onChange={e => setCurrentitem({...currentitem,jobnum:e.target.value})}/></Flex>
  <Flex><Label>Quote#</Label><br /><Input type="text" value={currentitem.quote} onChange={e => setCurrentitem({...currentitem,quote:e.target.value})}/></Flex>
  <Flex></Flex>
  <Flex flex="2" textAlign="right"><Button style={{marginRight:"30px"}} type="button" onClick={clear}>Clear Item</Button>
  {!currentitem._id && currentitem.name > "" && 
    <Button type="button" style={{marginRight:"30px"}} onClick={addtodatabase} >Add Item to Database</Button>
  }
    <Button type="button" onClick={additemtolist} >Save Item</Button></Flex>
  

</Flexed>
<hr style={{margin:"15px 0px",border:"2px solid grey"}}/>
{currentitems.length > 0 && <>
<Flexed bgColor="var(--plsGreen)" padding="0px 10px" >
<Flex>Job #</Flex>
<Flex>Quantity</Flex>
<Flex flex="2">Item</Flex>
<Flex>Order Unit</Flex>
<Flex>Unit Price</Flex>
<Flex>Price Unit</Flex>
<Flex>Ext Price</Flex>
<Flex flex=".5"></Flex>
</Flexed>
{currentitems?.map(item => {
 return  <Flexed >
<Flex onClick={e => setCurrentitem(item)}>{item.jobnum}</Flex>
<Flex onClick={e => setCurrentitem(item)}>{item.quantity}</Flex>
<Flex onClick={e => setCurrentitem(item)} flex="2">{item.name}</Flex>
<Flex onClick={e => setCurrentitem(item)}>{item.orderunit}</Flex>
<Flex onClick={e => setCurrentitem(item)}>{item.unitprice}</Flex>
<Flex onClick={e => setCurrentitem(item)}>{item.priceunit}</Flex>
<Flex onClick={e => setCurrentitem(item)}>{currency(item.extprice)}</Flex>
<Flex flex=".5"><MdDeleteOutline style={{fontSize:"1.1rem",color:"red",zIndex:"10"}} onClick={e => deleteitem(item.id)}/></Flex>
</Flexed>
})
  
  
}
</>}
<Flexed style={{marginTop:"30px"}}><Flex><Button >Save Purchase Order</Button></Flex><Flex flex="0" textAlign="right"><PrintComponent  poinfo={poinfo} /></Flex></Flexed>
<br /><br /><br /><br /><br /><br /><br /><br />
</form>
</Wrapper>
  )
}

export default Newpo