import { useTable, useSortBy, useGlobalFilter,useFilters,useRowSelect} from  'react-table'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Search from './search/Search'
import ColumnFilter from './ColumnFilter'
import { Checkbox } from './Checkbox'

export const Wrap = styled.div`
position:relative;
width: 100%;

`;


export const TableWrap = styled.table`
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
margin:0px auto;
border:1px solid red;

thead > tr{
    background-color:rgb(122,187,67,.3);

    th:first-child{
        flex:0;
        padding:0px 10px;
        margin-top:10px;

    }
}
tbody > tr{

    td:first-child{
        flex:0;
        padding:0px 10px;

    }
}
`;


export const TR = styled.tr`
display:flex;
justify-content:center;
align-items:flex-start;
border:1px solid rgb(0,0,0,.2);
padding:5px 0px;
`;


export const TH = styled.th`
flex:${props => props.flex || "1"};
word-break:break-all;
display:flex;
align-items:center;
position:relative;
padding:5px;
`;

export const TD = styled.td`
flex:${props => props.flex || "1"};
display:flex;
align-items:center;
word-break:break-all;
padding:5px;



`;


export const Span = styled.td`
position:absolute;
top:10px;
right:6px;



`;



 const Table = ({COLUMNS,DATA}) => {
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => DATA.slice(0,49), [])

  const defaultColumn = useMemo(() => {
    return {
        Filter:ColumnFilter
    }
  },[])
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
        selectedFlatRows
    } = useTable({
      columns,
      data,
  
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
        hooks.visibleColumns.push((columns) => {
return[
    {
        id: "selection",
        Header : ({getToggleAllRowsSelectedProps}) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({row}) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />  
        ),
        
  
        
    },
    ...columns
]
        })
    }
    )
  

    return (
      <>
     
        <TableWrap {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <TR {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TH {...column.getHeaderProps(column.getSortByToggleProps())} >{column.render('Header')}<br /><div>{column.canFilter ? column.render('Filter') : null } </div> <Span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </Span></TH>
                ))}
              </TR>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <TR {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                  })}
                </TR>
              )
            })}
          </tbody>
        </TableWrap>
       
      </>
    )
  }
  export default Table