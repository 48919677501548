import { AiFillFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Flex, Flexed,Fixed,Input,List } from '../../Shared'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Moment from 'react-moment'

const Jobs = () => {

const [jobs, setJobs] = useState()
const [search, setSearch] = useState("")


useEffect(() => {
const getAllJobs = async() => {

    await axios.get(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getalljobs`)
    .then( res => setJobs(res.data))
    .catch(err => console.log(err))
}
getAllJobs()
},[])


console.log(jobs)

    const nav = useNavigate()
  return (
<>

    <div style={{padding:"0px 20px",position:"relative"}}>
        
    <Fixed padding="30px 0px 0px 0px" style={{zIndex:"10"}}>
<Flexed>
          <Flex fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="var(--plsBlue)" textAlign="center" padding="10px 0px">CFP Jobs</Flex>
      </Flexed>
      <Flexed >
        <Flex ><Input type="text" height="30px" placeholder="Search Jobs" onChange={e => setSearch(e.target.value)}/></Flex>
       <Flex textAlign="right"> <AiFillFileAdd  onClick={() => nav('/jobs/new')} style={{position:"relative",fontSize:"+2rem",top:"4px",color:"var(--plsBlue)"}}/> </Flex> 
       </Flexed>
<Flexed bgColor="var(--plsGreen)" padding="6px 4px" color="white" margin="0">
<Flex>Invoice No</Flex>
            <Flex flex="2">Customer</Flex>
            <Flex>Date</Flex>
            <Flex flex="2.5">Job Description</Flex>
            <Flex>P.O.#</Flex>
</Flexed>
</Fixed>
<List >
{jobs?.reverse().filter(item => {return search.toLowerCase() === '' ? item : item.jobid.toString().includes(search) || item.customername.toLowerCase().includes(search)  || item.description.toLowerCase().includes(search) || item.po > "" && item.po.includes(search)  || item.description.toLowerCase().includes(search)}).map((job,index) => {
          return <>
          <Flexed key={index} className="hand" margin="0" padding="5px 0px" style={{flexWrap:"wrap"}} borderBottom="1px solid lightgrey"  onClick={() => nav(`/jobs/${job._id}`)}>
          <Flex onClick={() => nav(`/jobs/${job._id}`)}>{job.jobid}</Flex>
            <Flex flex="2">{job.customername}</Flex>
            <Flex>{<Moment format="MM/DD/YYYY">{job.lastdate}</Moment>}</Flex>
            <Flex flex="2.5">{job.description}</Flex>
            <Flex>{job.po}</Flex> 
          </Flexed>
          
          </>
       })}

</List>


</div>
</>
  )
}

export default Jobs