import React, { useEffect,useState,useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Datepick, Flex, Flexed, Input, Label, Textarea, Wrapper,PhoneNumber, Selectbox } from '../../Shared'
import axios from 'axios'
import Select from 'react-select'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import PrintComponent from '../../print/Jobticket';
import {IoMdAddCircleOutline } from 'react-icons/io'
import {GrDocumentUpdate,GrClear } from 'react-icons/gr'
import { PatternFormat } from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom'



const Newjob = () => {
const nav = useNavigate()
const [searchParams] = useSearchParams();

console.log(searchParams.get("id"))





    const [customers, setCustomers] = useState([])
    const [contacts, setContacts] = useState([])
    const [contact, setContact] = useState("")
    const [phone, setPhone] = useState("")
    const [customer, setCustomer] = useState({})
    const [customername, setCustomername] = useState("")
    const [addresslist, setAddresslist] = useState([])
    const [address, setAddress] = useState("")
   const [jobinfo, setJobinfo] = useState({})
      const [stamp, setStamp] = useState({})
const [currentstamp, setCurrentstamp] = useState({})
const [currentpress, setCurrentpress] = useState({})
const [ showprint, setShowprint] = useState(false)
const [ showaddress, setShowaddress] = useState(false)

const [test,setTest] = useState([])
useEffect(() => {

const getCustomers = async() => {
const t = await axios.get('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getall')
.then(res => {setCustomers(res.data)})
.catch(err => console.log(err))
}
getCustomers()
setJobinfo({...jobinfo,lastdate:new Date()})
},[])

const handle = (e) => {

    console.log(e.target.value)
}



const setcustomer = async(e) => {
    const name = {name:e.target.value}
    setCustomername(e.target.value)

    


    const t = await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/customers/getCustomer',name)
    .then(res => {console.log(res.data[0]);
        if(res.data[0].length === 0){
            setCustomer({});setContacts([]);setContact("");setPhone("");setAddress("");setAddresslist([])

        }else{
            console.log(res.data);
            setCustomer(res.data[0]);
            if(res.data[0]?.contacts){
                setContacts(res.data[0]?.contacts);setContact(res.data[0].contacts[0].name);
                setPhone(PhoneNumber(res.data[0]?.contacts[0].officephone));
                
            }
           if(res.data[0].address){
            setAddress(res.data[0]?.address[0]?.address1 || "" +" " +res.data[0]?.address[0]?.address2 || ""+" "+res.data[0]?.address[0]?.city || ""+", "+res.data[0]?.address[0]?.state || ""+" "+res.data[0]?.address[0]?.zip || "")
            setAddresslist(res.data[0]?.address);
           } 
      
        }
        
    })    
    .catch(err => console.log(err))
    
}


console.log(customer)

const addsched = (title,machine,start,status,hours) => {
    const y = {
        title,machine,start,status,hours
    }
    axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/schedules/add',y)
.then(res => {console.log(res.data);/*toast.success(`Your job has been saved. Job # is ${res.data.jobid}`,{autoClose: 20000});nav(`/jobs/${res.data._id}`)*/})
.catch(err => console.log(err))
}


const submit= async(e) => {
    e.preventDefault()
 const lat = {
    jobinfo:jobinfo,
    stamp:test,
    mainphone:phone,
    contact,
    customername,
    address,
    customerid:customer._id,
 }
 axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/jobs/addjob',lat)
 //axios.post('http://localhost:5007/jobs/addjob',lat)
.then(res => {addsched(`${res.data.customername}\n${res.data.description}`,`${res.data.machine}`,`${res.data.rundate}`,"On",`${res.data.hours}`);toast.success(`Your job has been saved. Job # is ${res.data.jobid}`,{autoClose: 20000});nav(`/jobs/${res.data._id}`)})
.catch(err => console.log(err))
console.log(lat)
}

console.log(jobinfo)
   

const clear = () => {
    setCurrentstamp({...currentstamp,stampcount:"",process:"",plates:"",passes:"",on:"",areal:"",areaw:"",sheetl:"",sheetw:"",sheets:"",overs:"",overpercent:"",id:"",overpercentquantity:""})
}

const addstamp = () => {
    if(currentstamp.process === ""){
 return toast.error('Please Select A Process')
    }
    if(currentstamp.overs === "Overs"){
        if(currentstamp.overpercent === ""){
            return toast.error('Please Select A Percentage Of Overs')
        }
           }
if(currentstamp.overs === ""){  
    return toast.error('Please Select Overs')
}   
if(currentstamp.id){
const objIndex = test.findIndex((obj => obj.id === currentstamp.id));
test[objIndex] = currentstamp
setTest(test)
}else{
    currentstamp.id = uuidv4()
    setTest(test => [...test,currentstamp]) 
}
clear()   
}

console.log(test)
const changepercent = (e) => {
let r = 0
r = (e.target.value/100) * currentstamp.sheets;
let t = parseInt(currentstamp.sheets) + parseInt(r)
setCurrentstamp({...currentstamp,overpercentquantity:t,overpercent:e.target.value})
}

const changepresspercent = (e) => {
    let r = 0
    r = (e.target.value/100) * currentpress.sheets;
    let t = parseInt(currentpress.sheets) + parseInt(r)
    setCurrentstamp({...currentpress,overpercentquantity:t,overpercent:e.target.value})
    }






  return (
    <Wrapper>
        <form onSubmit={submit}>
    <Flexed>
        <Flex textAlign="center" fontSize="calc(1.6rem + .4vw)">CFP New Job</Flex>
    </Flexed>
    <Flexed margin="0px 0px 25px 0px">
        <Flex textAlign="right"></Flex> 
    </Flexed>

    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0"> <Label>Customer</Label></Flex>
<Flex flex="1"><Input width="100%" list="customerlist" value={customername} name="" id="" onChange={e => setcustomer(e)} required/>
<datalist id="customerlist">
  {customers?.map((customer,index) => <option key={index} value={customer.name} />)}
</datalist></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Received Stock</Label></Flex>
<Flex flex="0" > <Datepick selected={jobinfo?.recievedstock} onChange={date => setJobinfo({...jobinfo,recievedstock:date})}/></Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
<Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ticket</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" onChange={e => setJobinfo({...jobinfo,ticket:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>

    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0" textAlign="right"> <Label>Contact&nbsp;&nbsp;&nbsp;</Label></Flex>
<Flex flex="1"><Input width="100%" list="contactlist" name="" id="" value={contact} onChange={e => setContact(e.target.value)}/>
<datalist id="contactlist">
  {contacts?.map((contact,index) => <option key={index} value={contact.firstname+" "+contact.lastname} />)}
</datalist></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Received Film</Label></Flex>
<Flex flex="0"> <Datepick selected={jobinfo?.recievedfilm} onChange={date => setJobinfo({...jobinfo,recievedfilm:date})}/></Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
                <Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Finish</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" onChange={e => setJobinfo({...jobinfo,finish:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>
    <Flexed>
        <Flex flex="1.5">
            <Flexed>
<Flex flex="0" textAlign="right"> <Label>Phone#&nbsp;&nbsp;&nbsp;</Label></Flex>
<Flex flex="1"><PatternFormat value={phone} format="(###) ### - ####" onChange={e => setPhone(e.target.value)} /></Flex>
            </Flexed>
            </Flex>
            <Flex>
                <Flexed>
<Flex flex="1" textAlign="right"> <Label >Need By</Label></Flex>
<Flex flex="0"> <Datepick selected={jobinfo?.needby} onChange={date => setJobinfo({...jobinfo,needby:date})}/></Flex>
</Flexed>
            </Flex>     
            <Flex>
                <Flexed>
                <Flex flex=".8" textAlign="right"> <Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C/B/C</Label></Flex>
<Flex flex="1" textAlign="right"><Input width="auto" type="text" onChange={e => setJobinfo({...jobinfo,cbc:e.target.value})}/> </Flex>
</Flexed>
            </Flex>    
    </Flexed>
<hr />
<Flexed margin="15px 0px 10px 0px">
<Flex><Textarea minRows="5" onChange={e => setJobinfo({...jobinfo,text1:e.target.value})}></Textarea></Flex>
<Flex><Textarea minRows="5" onChange={e => setJobinfo({...jobinfo,text2:e.target.value})}></Textarea></Flex>
</Flexed>
<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
<Flexed>

    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Foil Stamp & Emboss</Flex>
</Flexed>
<Flexed >

<Flex flex="1.3">
    <Selectbox value={currentstamp.process} onChange={e => setCurrentstamp({...currentstamp,process:e.target.value})}>
    <option value="">Process</option>  
    <option value="Stamp">Stamp</option>
    <option value="Emboss">Emboss</option>
      

    </Selectbox>
</Flex>
<Flex flex="0">{currentstamp.process}</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.stampcount} width="100%" onChange={e => setCurrentstamp({...currentstamp,stampcount:e.target.value})}/></Flex>

<Flex flex=".1"></Flex>
<Flex flex="0">Plates</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.plates} width="100%" onChange={e => setCurrentstamp({...currentstamp,plates:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Passes</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.passes} width="100%" onChange={e => setCurrentstamp({...currentstamp,passes:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">On</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.on} width="100%" onChange={e => setCurrentstamp({...currentstamp,on:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Area</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.areal} width="100%" onChange={e => setCurrentstamp({...currentstamp,areal:e.target.value})}/></Flex>
<Flex flex="0">X</Flex>
<Flex  flex="1"><Input value={currentstamp.areaw} type="text" width="100%" onChange={e => setCurrentstamp({...currentstamp,areaw:e.target.value})}/></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>Sheet Size</Label></Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.sheetl} width="100%" onChange={e => setCurrentstamp({...currentstamp,sheetl:e.target.value})}/></Flex>
<Flex flex="0">X</Flex>
<Flex  flex="1"><Input type="text" value={currentstamp.sheetw} width="100%" onChange={e => setCurrentstamp({...currentstamp,sheetw:e.target.value})}/></Flex>
<Flex flex=".1"></Flex>
<Flex flex="0">Sheets</Flex>
<Flex  flex="1"><Input type="text" width="100%" value={currentstamp.sheets} onChange={e => setCurrentstamp({...currentstamp,sheets:e.target.value})}/></Flex>
<Flex flex="2"></Flex>

</Flexed>
<Flexed>
<Flex flex="2">{/*<Label>Plates are</Label>*/}</Flex>


<Flex flex=".1"></Flex>
<Flex><Selectbox placeholder="No" value={currentstamp.overs} onFocus={e => setCurrentstamp({...currentstamp,overs:"",overpercent:""})} onChange={e => setCurrentstamp({...currentstamp,overs:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    <Flex flex=".1"></Flex>
   {currentstamp?.overs === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={currentstamp.overpercent} width="100%"  onChange={e => changepercent(e)}/></Flex>
</div> }<Flex flex=".1"></Flex>
<Flex flex="0" padding="0px 10px"  color="yellow" fontSize="calc(1.2rem + .4vw)">{test.length < 4 && <GrDocumentUpdate onClick={addstamp} />}</Flex>
<Flex flex="0" padding="0px 10px"  color="yellow" fontSize="calc(1.2rem + .4vw)">{test.length < 4 && <GrClear onClick={clear} />}</Flex>
</Flexed>

{
    test.length > 0 && <Flexed style={{background:"var(--plsGreen)",borderBottom:"1px solid grey"}}>
    <Flex>Process</Flex>
    <Flex>Stamp</Flex>
    <Flex>Plates</Flex>
    <Flex>Passes</Flex>
    <Flex>On</Flex>
    <Flex>Area Size</Flex>
    <Flex>Sheet Size</Flex>
    <Flex>Sheets</Flex>
    <Flex flex="2">Overs</Flex>
  </Flexed>
}
    {test?.map((item,index) => (
  
        <Flexed key={index} style={{borderBottom:"1px solid lightgrey"}} onClick={ e => setCurrentstamp(item)}>
    <Flex>{item.process}</Flex>
    <Flex>{item.stampcount}</Flex>
    <Flex>{item.plates}</Flex>
    <Flex>{item.passes}</Flex>
    <Flex>{item.on}</Flex>
    <Flex>{item.areal+" X "+item.areaw}</Flex>
    <Flex>{item.sheetl+" X "+item.sheetw}</Flex>
    <Flex>{item?.overpercent > 0 ? item.overpercentquantity+" Incl -" : item?.sheets}</Flex>
    <Flex flex="2">{item?.overpercent > 0 ? item?.overpercent+"% Overs" : item?.overs}</Flex>
  </Flexed>
    ))
}


<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>

<div style={{position:"relative"}}>
<Flexed>
<Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Die Room</Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>One Cutting Die</Label></Flex>
<Flex  flex="1"><Input type="text" value={jobinfo.cuttingdie}  onChange={e => setJobinfo({...jobinfo,cuttingdie:e.target.value})} /></Flex>
<Flex flex="0"><Label>On</Label></Flex>
<Flex  flex="5"><Input type="text"  value={jobinfo.cuttingon}  onChange={e => setJobinfo({...jobinfo,cuttingon:e.target.value})}/></Flex>
<Flex flex=".5"></Flex>
<Flex flex="0"><Label>Die #</Label></Flex>
<Flex  flex="1"><Input type="text" value={jobinfo.cuttingdie1}  onChange={e => setJobinfo({...jobinfo,cuttingdie1:e.target.value})}/></Flex>
<Flex flex="2.5"><Selectbox value={jobinfo.cuttingdiefurnished} onChange={e => setJobinfo({...jobinfo,cuttingdiefurnished:e.target.value})}>
    <option value="Have Die">Have Die</option>
    <option value="Die Furnished">Die Furnished</option>
    <option value="House Die">House Die</option>
    <option value="Die Re-Rule">Die Re-Rule</option>
    </Selectbox></Flex>
    <Flex flex="0"><Label>Die #</Label> </Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.cuttingdie2}  onChange={e => setJobinfo({...jobinfo,cuttingdie2:e.target.value})}/></Flex>
</Flexed>
</div>
    <hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>

   
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Press Room</Flex>
</Flexed>

<Flexed >
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pressdiecut} onChange={e => setJobinfo({...jobinfo,pressdiecut:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Die Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pressdiecut2} onChange={e => setJobinfo({...jobinfo,pressdiecut2:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>2nd Die Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.presskiss} onChange={e => setJobinfo({...jobinfo,presskiss:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Kiss Cut</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pressperf} onChange={e => setJobinfo({...jobinfo,pressperf:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Perforate</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pressscore} onChange={e => setJobinfo({...jobinfo,pressscore:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Score</Label></Flex>
    <Flex></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pressstrip} onChange={e => setJobinfo({...jobinfo,pressstrip:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Strip</Label></Flex>
</Flexed>
<Flexed >
<Flex ><Input type="text" value={jobinfo.presssheetscut} onChange={e => setJobinfo({...jobinfo,presssheetscut:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Sheets Cut</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text"value={jobinfo.presssheetstimes} onChange={e => setJobinfo({...jobinfo,presssheetstimes:e.target.value})} /></Flex>
    <Flex flex="0"><Label>Times for</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text" value={jobinfo.presssheetsimpressions} onChange={e => setJobinfo({...jobinfo,presssheetsimpressions:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Impressions</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Input type="text" value={jobinfo.presssheetson} onChange={e => setJobinfo({...jobinfo,presssheetson:e.target.value})}/></Flex>
    <Flex flex="0"><Label>On</Label></Flex>
    <Flex flex=".2"></Flex>
    <Flex ><Input type="text" value={jobinfo.presssheetsl} onChange={e => setJobinfo({...jobinfo,presssheetsl:e.target.value})}/></Flex>
    <Flex flex="0"><Label>X</Label></Flex>
    <Flex ><Input type="text" value={jobinfo.presssheetsw} onChange={e => setJobinfo({...jobinfo,presssheetsw:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Sheet Size</Label></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Input type="checkbox" selected={jobinfo.necessary} onChange={e => setJobinfo({...jobinfo,necessary:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Necessary</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.complete} onChange={e => setJobinfo({...jobinfo,complete:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Complete</Label></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.repeat} onChange={e => setJobinfo({...jobinfo,repeat:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Repeat</Label></Flex>
    <Flex flex="3"></Flex>
</Flexed>
<Flexed>
<Flex flex="0"><Label>Quantity Sheets</Label></Flex>
    <Flex ><Input type="text" value={jobinfo.presssheets} onChange={e => setJobinfo({...jobinfo,presssheets:e.target.value})}/></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Quantity Pieces</Label></Flex>
    <Flex ><Input type="text" value={jobinfo.presspieces} onChange={e => setJobinfo({...jobinfo,presspieces:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex><Selectbox  value={jobinfo.overs} onFocus={e => setJobinfo({...jobinfo,pressoverpercent:"",overs:""})} onChange={e => setJobinfo({...jobinfo,overs:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    {jobinfo?.overs === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={jobinfo.pressoverpercent} width="100%"  onChange={e => setJobinfo({...jobinfo,pressoverpercent:e.target.value})}/></Flex>
</div> }
</Flexed>


<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
  
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Glue Room</Flex>
</Flexed>

<Flexed >
    <Flex flex="0"><Label>Glue</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.glue} onChange={e => setJobinfo({...jobinfo,glue:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Pieces with</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.gluepieces1} onChange={e => setJobinfo({...jobinfo,gluepieces1:e.target.value})}/></Flex>
    <Flex flex="0"><Label>/</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.gluepieces2} onChange={e => setJobinfo({...jobinfo,gluepieces2:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Flaps</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Final Fold</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.gluefinalfold} onChange={e => setJobinfo({...jobinfo,gluefinalfold:e.target.value})}/></Flex>
    <Flex flex="0"><Label>Pieces</Label></Flex>
</Flexed>
<Flexed >
<Flex flex="0"><Input type="checkbox" selected={jobinfo.gluekraftwrap} onChange={e => setJobinfo({...jobinfo,gluekraftwrap:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Kraft Wrap In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,gluekraftquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.glueboxin} onChange={e => setJobinfo({...jobinfo,glueboxin:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Box In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,glueboxquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.glueshrinkwrap} onChange={e => setJobinfo({...jobinfo,glueshrinkwrap:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Shrink Wrap In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,glueshrinkquantity:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.gluerubberbandin} onChange={e => setJobinfo({...jobinfo,gluerubberbandin:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Rubber Band In</Label></Flex>
    <Flex flex="1"><Input type="text" onChange={e => setJobinfo({...jobinfo,gluerubberquantity:e.target.value})}/></Flex>
</Flexed>
<Flexed>
    <Flex flex="5"></Flex>
<Flex><Selectbox  value={jobinfo.glueovers} onFocus={e => setJobinfo({...jobinfo,glueoverpercent:"",glueovers:""})} onChange={e => setJobinfo({...jobinfo,glueovers:e.target.value})} >
<option value="" disabled selected>Overerage</option>
<option value="Run All">Run All</option>
<option value="No Overs">No Overs</option>
<option value="Overs">Overs</option>
    </Selectbox></Flex>
    {jobinfo?.glueovers === "Overs" && <div style={{display:"Flex",flex:"1"}}>
    <Flex flex="0"><Label>% Overs</Label></Flex>
<Flex  ><Input type="text" value={jobinfo.glueoverpercent} width="100%"  onChange={e => setJobinfo({...jobinfo,glueoverpercent:e.target.value})}/></Flex>
</div> }
</Flexed>

<hr style={{border:"1px solid Black",margin:"5px 0px 5px 0px"}}/>
  
<Flexed>
    <Flex textAlign="center" fontSize="calc(.7rem + .4vw)" fontWeight="bold">Shipping Instructions</Flex>
</Flexed>



<Flexed >
<Flex flex="0"><Input type="checkbox" selected={jobinfo.deliver} onChange={e => setJobinfo({...jobinfo,deliver:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Deliver</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.cod} onChange={e => setJobinfo({...jobinfo,cod:e.target.checked})} /></Flex>
    <Flex flex="0"><Label>C.O.D</Label></Flex>
    <Flex><Input type="text" value={jobinfo.codtext} onChange={e => setJobinfo({...jobinfo,codtext:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.pickup} onChange={e => setJobinfo({...jobinfo,pickup:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Customer Pick-up</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.otherpickup} onChange={e => setJobinfo({...jobinfo,otherpickup:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>Other</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Input type="checkbox" selected={jobinfo.ups} onChange={e => setJobinfo({...jobinfo,ups:e.target.checked})}/></Flex>
    <Flex flex="0"><Label>UPS</Label></Flex>
    <Flex flex=".5"></Flex>
</Flexed>

{jobinfo.deliver || jobinfo.cod ?
<Flexed>
    <Flex flex="1" padding="10px 0px"><Input width="100%" list="addresslistt" value={address} name="" id="" onChange={e => setAddress(e.target.value)}/>
<datalist id="addresslistt">
  {addresslist?.map((addy,index) => <option key={index} value={addy && addy?.address1+" "+addy?.address2+" "+addy?.city+", "+addy?.state+" "+addy?.zip} />)}
</datalist>
</Flex>
</Flexed>:""
}
<Flexed >
    <Flex flex="0"><Label>P.O.#</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.po} onChange={e => setJobinfo({...jobinfo,po:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Bid #</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.bid} onChange={e => setJobinfo({...jobinfo,bid:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"><Label>Customer Job #</Label></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.customerjobid} onChange={e => setJobinfo({...jobinfo,customerjobid:e.target.value})}/></Flex>
    <Flex flex=".5"></Flex>
    <Flex flex="0"></Flex>
    <Flex flex="1"></Flex>
    <Flex flex=".5"></Flex>
</Flexed>
<Flexed>
    <Flex flex="0">Return:</Flex>
<Flex flex="0"><Label>Artwork</Label></Flex>
    <Flex flex="0"><Input type="checkbox" onChange={e => setJobinfo({...jobinfo,returnartwork:e.target.checked})} /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Die</Label></Flex>
    <Flex flex="0"><Input type="checkbox" onChange={e => setJobinfo({...jobinfo,returndie:e.target.checked})} /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Plates</Label></Flex>
    <Flex flex="0"><Input type="checkbox"onChange={e => setJobinfo({...jobinfo,returnplates:e.target.checked})}  /></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Other</Label></Flex>
    <Flex flex="0"><Input type="checkbox" onChange={e => setJobinfo({...jobinfo,returnother:e.target.checked})}  /></Flex>
    <Flex flex="1"><Input type="text" value={jobinfo.returnothertext} onChange={e => setJobinfo({...jobinfo,returnothertext:e.target.value})}/></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="2"></Flex>
</Flexed>
<Flexed>
    <Flex flex="0"><Label>Job Description </Label></Flex>
    <Flex><Input type="text" value={jobinfo.description} onChange={e => setJobinfo({...jobinfo,description:e.target.value})} required/></Flex>
</Flexed>
<Flexed>
    <Flex flex="0"><Label>Machine</Label></Flex>
    <Flex><Selectbox onChange={e => setJobinfo({...jobinfo,machine:e.target.value})} required>
         <option value="">Select</option>
          <option value="L Miller">L Miller</option>
          <option value="S Miller">S Miller</option>
          <option value="Yawa">Yawa</option>
          <option value="Saro">Saro</option>
          <option value="Kluge 1">Kluge 1</option>
          <option value="Kluge 2">Kluge 2</option>
          <option value="Kluge 3">Kluge 3</option>
          <option value="Converer 1">Converer 1</option>
          <option value="Converer 2">Converer 2</option>
          <option value="Router">Router</option>
          <option value="Hand Work">Hand Work</option>
        </Selectbox></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Hours</Label></Flex>
    <Flex><Input type="text" value={jobinfo.hours} onChange={e => setJobinfo({...jobinfo,hours:e.target.value})} required/></Flex>
    <Flex flex=".1"></Flex>
    <Flex flex="0"><Label>Date to run</Label></Flex>
    <Flex><Datepick  selected={jobinfo?.rundate} onChange={date => setJobinfo({...jobinfo,rundate:date})} required/></Flex>
</Flexed>
<Flexed><Flex><Button >Save Job</Button></Flex></Flexed>
<br /><br /><br /><br /><br /><br /><br /><br />
</form>
    </Wrapper>
  )
}

export default Newjob