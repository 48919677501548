import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/CFPLOGO.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, Selectbox, Textarea, currency } from "../Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.7rem;
font-weight:bold;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-end"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
margin-right: ${props => props.marginRight || "0"};
`;

export const Line = styled.div`
height:60vh;
background-color:black;
position:absolute;
top:0;
right:${props =>props.right || ""};
width:1px;
z-index:10;
`;




export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;

`;


export default function PrintComponent({packinfo,customer,addresslist,address,today,jobinfo}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [totalsheets, setTotalsheets] = useState(0)


  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo()
  })
  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 console.log(packinfo)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <AiFillPrinter title="Packing Slip" style={{fontSize:"calc(1.4rem + .5vw)",float:"right",marginTop:"15px",}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint packinfo={packinfo} jobinfo={jobinfo} today={today} customer={customer} address={address} addresslist={addresslist} totalsheets={totalsheets} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    console.log(props)

  }
   

  render() {
    return (
 
<>
<Wrapper >
        <div style={{pageBreakAfter:"always"}}>
               
     <Flexed alignItems="flex-start">
       <Flex textAlign="left"> <Img src={LOGO} style={{height:"130px",top:"-20px",left:"-20px"}} /></Flex>
       <Flex textAlign="center" style={{textTransform:"capitalize",fontSize:".8rem"}}> complete die cutting service for printing trade<br />automatic pocket folder glueing<br/ >automatic foil stamping & embossing<br />transfer tape * Eyeletting * Wire-o coiling</Flex>
       <Flex textAlign="right" style={{fontSize:".8rem"}}> 4250 118th Ave N<br />Clearwater, FL 33762<br /><br />Phone (727) 572-8088<br />Fax: (727) 573-2662</Flex>
    </Flexed>

   <hr style={{height:"2px"}}/>
<br />

<Flexed alignItems="flex-start" width="100%">
    <Flex flex="1.5"><Label>Customer</Label><br />{this.props.customer.name}<br /></Flex>
    <Flex flex="1.5"><Label>Ship To</Label><br />
   
   <Textarea textAlign="left" border="none" outline="none" value={this.props.packinfo?.shipto}></Textarea>
    
    </Flex>
    <Flex><Label>Date</Label><br />{<Moment format="MM/DD/YYY" >{this.props.today}</Moment>}</Flex>
    <Flex flex="0"><Label>CFP Job #</Label><br /><span style={{fontSize:"1.3rem"}}>{this.props.packinfo?.packpo}</span></Flex>
</Flexed>
{this.props.jobinfo.customerjobid > "" && <Flexed style={{fontSize:".9rem"}} padding="5px 0px" >Customer Job #: {this.props.jobinfo.customerjobid} </Flexed>}
<Flexed justifyContent="flex-start" style={{fontSize:".9rem"}}><Flex marginRight="20px" flex="0" >Returning:</Flex><Flex flex="0" marginRight="5px"><Input type="checkbox" checked={this.props.jobinfo?.returnartwork} /></Flex><Flex marginRight="30px" flex="0" >Artwork</Flex><Flex marginRight="5px" flex="0"><Input type="checkbox" checked={this.props.jobinfo?.returnplates} /></Flex><Flex marginRight="30px" flex="0" >Plates</Flex><Flex marginRight="5px" flex="0" ><Input type="checkbox" checked={this.props.jobinfo?.returndie} /></Flex><Flex marginRight="30px" flex="0" >Die</Flex><Flex marginRight="5px" flex="0" ><Input type="checkbox" checked={this.props.jobinfo?.returnother} /></Flex><Flex marginRight="5px" flex="0" >Other:</Flex><Flex marginRight="5px"><Input type="text" textalign="left" value={this.props.jobinfo?.returnother && this.props.jobinfo.returnothertext ? this.props.jobinfo.returnothertext : ""}  /></Flex></Flexed>
<Flexed alignItems="flex-start" width="100%" style={{background:"rgb(0,0,0,.2)",gap:"15px",border:"1px solid black",padding:"3px 7px"}}>
<Flex ># of Packages</Flex>
<Flex flex="1.1">Quantity / Package</Flex>
<Flex >Total Quantity</Flex>
<Flex flex="3">Description</Flex>
<Flex >PO / Job #</Flex>
</Flexed>
<div style={{border:"1px solid black",height:"60vh",position:"relative"}}>
<Line right="105px"></Line>
<Line right="398px"></Line>
<Line right="506px"></Line>
<Line right="623px"></Line>

{this.props.packinfo.shiplist?.map(item => {
 return <Flexed alignItems="flex-start" width="100%" style={{gap:"15px",padding:"3px 7px"}}>

<Flex >{item?.shipnumpackages}</Flex>
<Flex flex="1.1">{item?.shipperpackages}</Flex>
<Flex >{item?.shiptotal}</Flex>
<Flex flex="3" whiteSpace="wrap" >{item?.shipdescription}</Flex>
<Flex >{this.props.packinfo?.shippo}</Flex>
</Flexed>
})}



{this.props.packinfo.partial === true ? <div style={{position:"absolute",bottom:"0",right:"35%",fontSize:"1.1rem"}}>Partial Delivery</div> : <div style={{position:"absolute",bottom:"0",right:"35%",fontSize:"1.1rem"}}>Job Completed</div> }
</div>

<Flexed style={{gap:"10px",marginTop:"5px"}}>
    <Flex><Label>Received By<br /></Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex><Label>Delivered By<br /></Label></Flex>
   
</Flexed>
<br /><br />
<Flexed style={{gap:"10px",marginTop:"5px"}}>
    <Flex style={{borderBottom:"1px solid black"}}></Flex>
    <Flex flex=".5"></Flex>
    <Flex style={{borderBottom:"1px solid black"}}></Flex>
   
</Flexed>
   </div>





  





   <div style={{pageBreakAfter:"always",marginTop:"25px"}}>

                 
   <Flexed alignItems="flex-start">
       <Flex textAlign="left"> <Img src={LOGO} style={{height:"130px",top:"-20px",left:"-20px"}} /></Flex>
       <Flex textAlign="center" style={{textTransform:"capitalize",fontSize:".8rem"}}> complete die cutting service for printing trade<br />automatic pocket folder glueing<br/ >automatic foil stamping & embossing<br />transfer tape Wire-o coiling</Flex>
       <Flex textAlign="right" style={{fontSize:".8rem"}}> 4250 118th Ave N<br />Clearwater, FL 33762<br /><br />Phone (727) 572-8088<br />Fax: (727) 573-2662</Flex>
    </Flexed>

   <hr style={{height:"2px"}}/>
<br />

<Flexed alignItems="flex-start" width="100%">
    <Flex flex="1.5"><Label>Customer</Label><br />{this.props.customer.name}<br /></Flex>
    <Flex flex="1.5"><Label>Ship To</Label><br />
   
   <Textarea textAlign="left" border="none" outline="none" value={this.props.packinfo?.shipto}></Textarea>
    
    </Flex>
    <Flex><Label>Date</Label><br />{<Moment format="MM/DD/YYY" >{this.props.today}</Moment>}</Flex>
    <Flex flex="0"><Label>CFP Job #</Label><br /><span style={{fontSize:"1.3rem"}}>{this.props.packinfo?.packpo}</span></Flex>
</Flexed>
{this.props.jobinfo.customerjobid > "" && <Flexed style={{fontSize:".9rem"}} padding="3px 0px" >Customer Job #: {this.props.jobinfo.customerjobid} </Flexed>}
<Flexed justifyContent="flex-start" style={{fontSize:".9rem"}}><Flex marginRight="20px" flex="0" >Returning:</Flex><Flex flex="0" marginRight="5px"><Input type="checkbox" checked={this.props.jobinfo?.returnartwork} /></Flex><Flex marginRight="30px" flex="0" >Artwork</Flex><Flex marginRight="5px" flex="0"><Input type="checkbox" checked={this.props.jobinfo?.returnplates} /></Flex><Flex marginRight="30px" flex="0" >Plates</Flex><Flex marginRight="5px" flex="0" ><Input type="checkbox" checked={this.props.jobinfo?.returndie} /></Flex><Flex marginRight="30px" flex="0" >Die</Flex><Flex marginRight="5px" flex="0" ><Input type="checkbox" checked={this.props.jobinfo?.returnother} /></Flex><Flex marginRight="5px" flex="0" >Other:</Flex><Flex marginRight="5px"><Input type="text" textalign="left" value={this.props.jobinfo?.returnother && this.props.jobinfo.returnothertext ? this.props.jobinfo.returnothertext : ""}  /></Flex></Flexed>
<Flexed alignItems="flex-start" width="100%" style={{background:"rgb(0,0,0,.2)",gap:"15px",border:"1px solid black",padding:"3px 7px"}}>
<Flex ># of Packages</Flex>
<Flex flex="1.1">Quantity / Package</Flex>
<Flex >Total Quantity</Flex>
<Flex flex="3">Description</Flex>
<Flex >PO / Job #</Flex>
</Flexed>
<div style={{border:"1px solid black",height:"60vh",position:"relative"}}>
<Line right="105px"></Line>
<Line right="398px"></Line>
<Line right="506px"></Line>
<Line right="623px"></Line>
{this.props.packinfo.shiplist?.map(item => {
 return <Flexed alignItems="flex-start" width="100%" style={{gap:"15px",padding:"3px 7px"}}>

<Flex >{item?.shipnumpackages}</Flex>
<Flex flex="1.1">{item?.shipperpackages}</Flex>
<Flex >{item?.shiptotal}</Flex>
<Flex flex="3" whiteSpace="wrap" >{item?.shipdescription}</Flex>
<Flex >{this.props.packinfo?.shippo}</Flex>
</Flexed>
})}
{this.props.packinfo.partial === true ? <div style={{position:"absolute",bottom:"0",right:"35%",fontSize:"1.1rem"}}>Partial Delivery</div> : <div style={{position:"absolute",bottom:"0",right:"35%",fontSize:"1.1rem"}}>Job Completed</div> }
</div>

<Flexed style={{gap:"0px",marginTop:"5px"}}>
    <Flex><Label>Received By<br /></Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex><Label>Delivered By<br /></Label></Flex>
   
</Flexed>
<br /><br />
<Flexed style={{gap:"0px",marginTop:"-10px"}}>
    <Flex style={{borderBottom:"1px solid black"}}></Flex>
    <Flex flex=".5"></Flex>
    <Flex style={{borderBottom:"1px solid black"}}></Flex>
   
</Flexed>
   </div>
   </Wrapper>
</>
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
