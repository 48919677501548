import { AiFillFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Flex, Flexed,Fixed,Input,List, currency } from './Shared'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Moment from 'react-moment'

const Mainpage = () => {
  const [jobs, setJobs] = useState()
  const [search, setSearch] = useState("")
  const [pocost, setPocost] = useState([])
  const [total, setTotal] = useState(0)
  const [sales, setSales] = useState({})
  const [reset, setReset] = useState(false)
  
  useEffect(() => {
  const getAllJobs = async() => {
      await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/open/jobs`)
      .then( res => setJobs(res.data))
      .catch(err => console.log(err))
  }
    
  const getAllSales = async() => { 
   await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/all/sales`)
   .then(res => {console.log(res.data);setSales(res.data) })
   .catch(err => console.log(err))
}
  const getAllPOS = async() => {
    await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/pos/getall`)
    .then( res => setPocost(res.data))
    .catch(err => console.log(err))
}
  getAllJobs()
  getAllSales()
  getAllPOS()
  },[reset])
  
  useEffect(() => {
    const addit = () => {
    let u = 0
    pocost.items?.map(item => {
   return   u += (parseInt(item.quantity) * item.unitprice)
        })
        setTotal(u)
      }
      addit()
  },[])
  
  console.log(total)
  
      const nav = useNavigate()
    return (
      <div style={{padding:"0px 20px",position:"relative"}}>

<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px">
    <Flex textAlign="center">Today</Flex>
    <Flex textAlign="center">This Week</Flex>
    <Flex textAlign="center">Last Week</Flex>
    <Flex textAlign="center">This Month</Flex>
    <Flex textAlign="center">QTD</Flex>
    <Flex textAlign="center">YTD</Flex>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
    <Flex textAlign="center">${currency(sales?.today)}</Flex>
    <Flex textAlign="center">${currency(sales?.week)}</Flex>
    <Flex textAlign="center">${currency(sales?.lastweek)}</Flex>
    <Flex textAlign="center">${currency(sales?.month)}</Flex>
    <Flex textAlign="center">${currency(sales?.quarter)}</Flex>
    <Flex textAlign="center">${currency(sales?.year)}</Flex>
</Flexed>


      <Fixed padding="30px 0px 0px 0px" style={{zIndex:"10"}}>
  <Flexed>
            <Flex fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="var(--plsBlue)" textAlign="center" padding="10px 0px">CFP Current Open Jobs -- {jobs?.length}</Flex>
        </Flexed>
        <Flexed >
          <Flex ><Input type="text" height="30px" placeholder="Search Jobs" onChange={e => setSearch(e.target.value)}/></Flex>
         <Flex textAlign="right"> <AiFillFileAdd  onClick={() => nav('/jobs/new')} style={{position:"relative",fontSize:"+2rem",top:"4px",color:"var(--plsBlue)"}}/> </Flex> 
         </Flexed>
  <Flexed bgColor="var(--plsGreen)" padding="6px 4px" color="white" margin="0">
  <Flex>Invoice No</Flex>
              <Flex flex="2">Customer</Flex>
              <Flex>Date</Flex>
              <Flex flex="2.5">Job Description</Flex>
              <Flex>P.O.#</Flex>
  </Flexed>
  </Fixed>
  <List style={{maxHeight:"500px",overflowY:"scroll"}}>
  {jobs?.filter(item => {return search.toLowerCase() === '' ? item : item.jobid.toString().includes(search) || item.customername.toLowerCase().includes(search)  || item.description.toLowerCase().includes(search) || item.po > "" && item.po.includes(search)  || item.description.toLowerCase().includes(search)}).map((job,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px" style={{flexWrap:"wrap"}} borderBottom="1px solid lightgrey"  onClick={() => nav(`/jobs/${job._id}`)}>
            <Flex onClick={() => nav(`/jobs/${job._id}`)}>{job.jobid}</Flex>
              <Flex flex="2">{job.customername}</Flex>
              <Flex>{<Moment format="MM/DD/YYYY">{job.lastdate}</Moment>}</Flex>
              <Flex flex="2.5">{job.description}</Flex>
              <Flex>{job.po}</Flex> 
            </Flexed>
            
            </>
         })}
  
  </List>
  <Flexed gap="20px" style={{marginTop:"50px"}}>
<Flex style={{maxHeight:"150px",minHeight:"150px",overflowY:"auto"}}>
  <Flexed margin="0" padding="0" ><Flex textAlign="center" bgColor="#8398AB" color="white"><h3>Jobs Logged Today</h3></Flex></Flexed>
  <Flexed margin="0" padding="0" borderBottom="1px solid grey"><Flex>Job</Flex><Flex>Customer</Flex><Flex>Due</Flex><Flex>Price</Flex></Flexed>
  <Flexed><Flex></Flex><Flex></Flex><Flex></Flex><Flex></Flex></Flexed>
</Flex>

<Flex style={{maxHeight:"150px",minHeight:"150px",overflowY:"auto"}}>
<Flexed margin="0" padding="0" ><Flex textAlign="center" bgColor="#8398AB" color="white"><h3>Po's Logged Today</h3></Flex></Flexed>
<Flexed margin="0" padding="0" borderBottom="1px solid grey"><Flex>Job</Flex><Flex>Customer</Flex><Flex>Due</Flex><Flex>Price</Flex></Flexed>
  <Flexed><Flex></Flex><Flex></Flex><Flex></Flex><Flex></Flex></Flexed>
</Flex>
  </Flexed>
  
  <Flexed gap="20px" style={{marginTop:"25px"}}>
<Flex style={{maxHeight:"150px",minHeight:"150px",overflowY:"auto"}}>
  <Flexed margin="0" padding="0"><Flex textAlign="center" bgColor="#8398AB" color="white"><h3>Jobs Logged Yesturday</h3></Flex></Flexed>
  <Flexed margin="0" padding="0" borderBottom="1px solid grey"><Flex>PO</Flex><Flex>Customer</Flex><Flex>Due</Flex><Flex>Price</Flex></Flexed>
  <Flexed><Flex></Flex><Flex></Flex><Flex></Flex><Flex></Flex></Flexed>
</Flex>

<Flex style={{maxHeight:"150px",minHeight:"150px",overflowY:"auto"}}>
<Flexed margin="0" padding="0" ><Flex textAlign="center" bgColor="#8398AB" color="white"><h3>PO's Logged Yesturday</h3></Flex></Flexed>
<Flexed margin="0" padding="0" borderBottom="1px solid grey"><Flex>PO</Flex><Flex>Customer</Flex><Flex>Due</Flex><Flex>Price</Flex></Flexed>
  <Flexed><Flex></Flex><Flex></Flex><Flex></Flex><Flex></Flex></Flexed>
</Flex>
  </Flexed>

  </div>
  
    )
  }

export default Mainpage