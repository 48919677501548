import React, { useContext, useEffect, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Img, Input, Label, PhoneNumber, Selectbox } from '../../Shared'
import States from '../../Util/States'
import Male from '../../Assets/male.jpg'
import Default from '../../Assets/default.png'
import Female from '../../Assets/female.jpg'
import { Checkbox } from '../../components/table/Checkbox'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import UserContext from '../../Context/UserContext'




const AddEmployee = () => {


const {userInfo} = useContext(UserContext);  

const [image, setImage] = useState("b")
const [empinfo, setEmpinfo] = useState({state:"FL"})
const [changepassword, setChangepassword] = useState(false)
const [newpass, setNewpass] = useState()
const [newpassconfirm, setNewpassconfirm] = useState()
const idOfemp = useParams();
useEffect(() => {
    switch(empinfo?.gender){
        case "M":
            setImage(Male)
            break;
            case "F":
                setImage(Female)
                break;  
            default: 
            setImage(Default)
            break; 
        }    
},[empinfo?.gender]) 


    useEffect(() => {
        const getSelectedEmp = async() => {
       let birthday
       let hiredate
       let disdate
              let u = {
                  "id":idOfemp.id
              }
              await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/getemployee',u)
              .then(res => {
                if(res.data.birthDate){birthday=new Date(res.data.birthDate)}else{birthday = null}
                if(res.data.hireDate){hiredate=new Date(res.data.hireDate)}else{hiredate = null}
                if(res.data.disDate){disdate=new Date(res.data.disDate)}else{disdate = null}
                setEmpinfo({...res.data,birthDate:birthday,hireDate:hiredate,disDate:disdate})
              })
              .catch((err) => console.log(err))
          }
          getSelectedEmp()
    },[])
     






const checkpassword = async() => {
if(newpass === "" ){ return toast.error('Password can not be blank')}

if(newpass === newpassconfirm){
   
  const r = {
    "id":idOfemp.id,
    "password":newpass,
  }
    const getemp = async() => {
       await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/change/pass',r)
        .then(res => {setNewpass("");setNewpassconfirm("");toast.success('Password Change Saved');setChangepassword(false)})
       .catch(err => console.log(err))
    }
    getemp()
    console.log(r)
}else{
    setNewpass("");
    setNewpassconfirm("");
    toast.error('Passwords Do Not Match')
}
}


const submit = async(e) => {
e.preventDefault()
const editemp = async() => {
    await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/update/employee',empinfo)
     .then(res => {toast.success('Employee Changes Saved')})
    .catch(err => console.log(err))
 }
 editemp()

}

console.log(empinfo)

  return (
    <div>
        <Flexed textTransform="none"><Flex padding="30px" textTransform="none" textAlign="center" fontSize="calc(1.4rem + .4vw)" fontWeight="bold">{empinfo.firstName+"'s"} Profile</Flex></Flexed>
        <form onSubmit={submit}>   
<Flexed  gap="20px">
<div textAlign="center"><Img src={image} margin="10px" width={empinfo?.gender === "M" ? "130px": "100px"} padding="0" borderRadius="50%" style={{position:"absolute",top:"-70%",left:"9%"}} /></div>
<Flex></Flex>
    <Flex><br /><Label>First Name</Label><br /><Input type="text" value={empinfo?.firstName} onChange={e => setEmpinfo({...empinfo,firstName:e.target.value,preferredName:e.target.value,userName:e.target.value})} required/></Flex>
    <Flex><br /><Label>Last Name</Label><br /><Input type="text" value={empinfo?.lastName} onChange={e => setEmpinfo({...empinfo,lastName:e.target.value})} required/></Flex>
    <Flex flex=".65"><br /><Label>Prefers Name</Label><br /><Input type="text" value={empinfo?.preferredName} onChange={e => setEmpinfo({...empinfo,preferredName:e.target.value})}/></Flex>
</Flexed>
<Flexed  gap="20px">
<Flex textAlign="center">Upload Photo<Input type="file" style={{marginLeft:"30px",border:"0"}}/></Flex>
<Flex flex="1.25" ><Label>Address 1</Label><br /><Input type="text" value={empinfo?.address1} onChange={e => setEmpinfo({...empinfo,address1:e.target.value})}/></Flex>
<Flex flex="1.25" ><Label>Address2</Label><br /><Input type="text" value={empinfo?.address2} onChange={e => setEmpinfo({...empinfo,address2:e.target.value})}/></Flex>
</Flexed>

<Flexed  gap="20px">
<Flex></Flex>
<Flex ><Label>City</Label><br /><Input type="text" value={empinfo?.city} onChange={e => setEmpinfo({...empinfo,city:e.target.value})}/></Flex>
<Flex ><Label>State</Label><br /><States value={empinfo?.state} onChange={e => setEmpinfo({...empinfo,state:e.target.value})}/></Flex>
<Flex flex=".4"><Label>Zip</Label><br /><Input type="text" value={empinfo?.zip} onChange={e => setEmpinfo({...empinfo,zip:e.target.value})}/></Flex>
</Flexed>


<Flexed  gap="20px" margin="10px 0px 0px 0px">
<Flex ><Label>Employee ID</Label><br /><Input type="text" value={empinfo?.empId || "Not Assigned Yet"} disabled/></Flex>
<Flex ><Label>Personal Email</Label><br /><Input type="email" value={empinfo?.email} onChange={e => setEmpinfo({...empinfo,email:e.target.value})}/></Flex>
<Flex ><Label>Work Email</Label><br /><Input type="email" value={empinfo?.workemail} onChange={e => setEmpinfo({...empinfo,workemail:e.target.value})}/></Flex>
<Flex ><Label>Phone</Label><br /><Input type="tel" value={PhoneNumber(empinfo?.cell)} onChange={e => setEmpinfo({...empinfo,cell:e.target.value})}/></Flex>
</Flexed>

<Flexed margin="10px 0px 0px 0px">
<Flex><Label>D.O.B</Label><br /><Datepick  selected={empinfo?.birthDate  && empinfo?.birthDate}  onChange={date => setEmpinfo({...empinfo,birthDate:date})} /> </Flex>
<Flex><Label>Hire Date</Label><br /><Datepick  selected={empinfo?.hireDate  && empinfo?.hireDate}  onChange={date => setEmpinfo({...empinfo,hireDate:date})} /> </Flex>
<Flex><Label>Dismissal Date</Label><br /><Datepick  selected={empinfo?.disDate  && empinfo?.disDate}  onChange={date => setEmpinfo({...empinfo,disDate:date})} /> </Flex>
<Flex><Label>Status</Label><br /><Selectbox  value={empinfo?.status} onChange={e => setEmpinfo({...empinfo,status:e.target.value})} required disabled={userInfo.access === 102 ? true : false}>
<option value="" selected disabled>Select Status</option>
    <option value={true} >Active</option>
    <option value={false}>Inactive</option>
    </Selectbox></Flex>

 
</Flexed>

<Flexed  gap="20px" margin="10px 0px 0px 0px">
<Flex>Gender<br /><Selectbox  value={empinfo?.sex} onChange={e => setEmpinfo({...empinfo,sex:e.target.value})} required>
<option value="" selected disabled>Select Gender</option>
    <option value="M">Male</option>
    <option value="F">Female</option>
    </Selectbox></Flex>
<Flex ><Label>Access Level</Label><br /><Selectbox  value={empinfo?.access} onChange={e => setEmpinfo({...empinfo,access:e.target.value})} required disabled={userInfo.access === 102 ? true : false}>
<option value="" selected disabled>Select Access</option>
    <option value="106" disabled={userInfo.access !== 106}>Admin</option>
    <option value="101">Manager</option>
    <option value="102">User</option>
    </Selectbox></Flex>
<Flex ><Label>F.O.B ID</Label><br /><Input type="text" value={empinfo?.fob} onChange={e => setEmpinfo({...empinfo,fob:e.target.value})}/></Flex>
</Flexed>


<Flexed  gap="20px" margin="10px 0px 0px 0px">
<Flex ><Label>User Name</Label><br /><Input type="text" textTransform="none" value={empinfo?.userName} onChange={e => setEmpinfo({...empinfo,userName:e.target.value})} /></Flex>
{!changepassword && <Flex flex="3" display="flex" alignItems="flex-end"><Button type="button" onClick={e => setChangepassword(true)}>Change Password</Button></Flex>}
{changepassword && <><Flex ><Label>New Password</Label><br /><Input textTransform="none" type="password" value={newpass} onChange={e => setNewpass(e.target.value)}/></Flex>
<Flex ><Label>Confirm New Password</Label><br /><Input type="password" textTransform="none" value={newpassconfirm} onChange={e => setNewpassconfirm(e.target.value)}/></Flex></>}
</Flexed>
{changepassword && <>
<Flexed margin="10px 0px 0px 0px">
    <Flex flex="4.5"></Flex>
<Flex><Button type="button" onClick={e => setChangepassword(false)}>Cancel</Button></Flex>
<Flex textAlign="right" ><Button type="button" onClick={checkpassword} >Change</Button></Flex>
</Flexed>
</>}

<Flexed  gap="20px" margin="10px 0px 0px 0px">
<Flex><Label color="rgb(255,0,0,.7)">Emergency Contact</Label><br /><Input type="text" value={empinfo?.econtact?.name} onChange={e => setEmpinfo({...empinfo,econtact:{...empinfo.econtact,name:e.target.value}})} /></Flex>
<Flex ><Label color="rgb(255,0,0,.7)">Phone</Label><br /><Input type="text" value={PhoneNumber(empinfo?.econtact?.cell)} onChange={e => setEmpinfo({...empinfo,econtact:{...empinfo.econtact,cell:e.target.value}})} /></Flex>
<Flex ><Label color="rgb(255,0,0,.7)">Relationship to Employee</Label><br /><Input type="text" value={empinfo?.econtact?.relationship} onChange={e => setEmpinfo({...empinfo,econtact:{...empinfo.econtact,relationship:e.target.value}})}/></Flex>
</Flexed>

{/*<div style={{border:"1px solid grey",marginTop:"25px"}}>
Permissions
<Flexed  gap="20px" justifyContent="space-around" >
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Customers</span></Flex>
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Vendors</span></Flex>
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Employees</span></Flex>
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Jobs</span></Flex>
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Quotes</span></Flex>
<Flex  display="flex" alignItems="center" ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>P.O.s</span></Flex>
</Flexed>
<Flexed  gap="20px" >
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add </span><Input type="checkbox" height="18px"/></Flex>
</Flexed>
<Flexed  gap="20px" >
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add Quotes</span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Edit Customers</span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add Customers</span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add Customers</span><Input type="checkbox" height="18px"/></Flex>
<Flex  display="flex"  ><span style={{whiteSpace:"nowrap",marginRight:"10px"}}>Add Customers</span><Input type="checkbox" height="18px"/></Flex>
</Flexed>
</div>*/}

<Flexed><Flex textAlign="right" padding="30px 0px"><Button> Edit Employee</Button></Flex></Flexed>
</form>
    </div>
  )
}

export default AddEmployee