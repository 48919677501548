import { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox, Textarea, Wrapper } from '../../Shared'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const NewCustomer = () => {

    const [newinfo,setNewinfo] = useState({})
    const [address,setAddress] = useState({})
    const [contact,SetContact] = useState({})
const nav = useNavigate()

const addNewCustomer = async(e) => {
    e.preventDefault()
   
       const r = {
newinfo,
address,
contact
       }
const y = axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/customers/addcustomer', r)
.then(res => console.log(res.data))
.catch(err => console.log())
}




  return (
    <Wrapper>
<Flexed>
    <Flex textAlign="center" fontSize="calc(1.6rem + .4vw)">CFP New Customer</Flex>
</Flexed>
<Flexed margin="0px 0px 25px 0px">
    <Flex textAlign="right"></Flex> 
</Flexed>
    <form onSubmit={e => addNewCustomer(e)}>
    <Flexed gap="20px" margin="0px 0px 25px 0px">
        <Flex><Label>Company Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
      
    </Flexed>
        <Flexed gap="20px" margin="0px 0px 15px 0px">
        <Flex><Label>Main Location Name</Label><Input type="text" value={address?.name} onChange={e => setAddress({...address,name:e.target.value})}/></Flex>
        <Flex><Label>Main Address</Label><Input type="text" value={address?.address1} onChange={e => setAddress({...address,address1:e.target.value})}/></Flex>
        <Flex><Label>Address 2</Label><Input type="text" value={address?.address2} onChange={e => setAddress({...address,address2:e.target.value})}/></Flex>
       
    </Flexed>
    <Flexed gap="20px" margin="0px 0px 25px 0px">
      
        <Flex ><Label>City</Label><Input type="text" value={address?.city} onChange={e => setAddress({...address,city:e.target.value})}/></Flex>
        <Flex flex=".6"><Label>State</Label><Input type="text" value={address?.state} onChange={e => setAddress({...address,state:e.target.value})}/></Flex>
        <Flex flex=".6"><Label>Zip</Label><Input type="text" value={address?.zip} onChange={e => setAddress({...address,zip:e.target.value})} /></Flex>
        <Flex flex=".8"><Label>Phone</Label><Input type="text" value={PhoneNumber(address?.officephone)} onChange={e => setAddress({...address,officephone:e.target.value})}/></Flex>
        <Flex flex=".8"><Label>Attn</Label><Input type="text" value={address?.attn} onChange={e => setAddress({...address,attn:e.target.value})} /></Flex>
    </Flexed>
    
    <hr style={{color:"lightgrey"}} /> 
    <Flexed margin="15px 0px 25px 0px"> 
    

    <Flex><Label>Main Contact</Label><Input type="text" value={contact.name} onChange={e => SetContact({...contact,name:e.target.value})}/></Flex>
    <Flex><Label>Title</Label><Input type="text" value={contact.title} onChange={e => SetContact({...contact,title:e.target.value})}/></Flex>
    <Flex><Label>Email</Label><Input type="email" value={contact.email} onChange={e => SetContact({...contact,email:e.target.value})}/></Flex>
</Flexed>
<Flexed>
      
<Flex ><Label>Office Phone</Label><Input type="text" value={PhoneNumber(contact?.officephone)} onChange={e => SetContact({...contact,officephone:e.target.value})}/></Flex>    
<Flex flex=".3"><Label>Ext</Label><Input type="text" value={contact?.ext} onChange={e => SetContact({...contact,ext:e.target.value})}/></Flex>  
<Flex ><Label>Cell Phone</Label><Input type="text" value={PhoneNumber(contact?.cell)} onChange={e => SetContact({...contact,cell:e.target.value})}/></Flex>
       <Flex flex=".6"><Label>Invoices</Label><Input type="text" value={contact?.invoices} onChange={e => SetContact({...contact,invoices:e.target.value})}/></Flex>
</Flexed>
<hr style={{color:"lightgrey"}} /> 

    <Flexed gap="20px" margin="15px 0px 25px 0px">
    <Flex flex="2.5"><Label>Website</Label><Input type="text" value={newinfo?.website} onChange={e => setNewinfo({...newinfo,website:e.target.value})} /></Flex>
    <Flex><Label>Billing Terms</Label><Input type="text" value={newinfo?.billingterms} onChange={e => setNewinfo({...newinfo,billingterms:e.target.value})} /></Flex>
    <Flex><Label>Tax Exempt</Label><Input type="text" value={newinfo?.taxexempt} onChange={e => setNewinfo({...newinfo,taxexempt:e.target.value})} /></Flex>
    </Flexed>   
 



    <Flexed gap="20px">
    <Flex><Label>Notes</Label><br /><Textarea rows="5" value={newinfo?.notes} onChange={e => setNewinfo({...newinfo,notes:e.target.value})}></Textarea></Flex>

        <Flex display="flex" justifyContent="flex-end" alignItems="flex-end"><Button height="30px">Add Customer</Button></Flex>
    </Flexed>   
    </form>
    </Wrapper>
  )
}

export default NewCustomer