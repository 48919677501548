import React from 'react'
import { Flex, Flexed, PhoneNumber, Wrapper, currency, AddIcon, EditIcon } from '../../Shared'
import { useEffect } from 'react'
import axios from 'axios'
import { useParams,useNavigate } from 'react-router-dom'
import { useState } from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import Moment from 'react-moment'
import Modal from '../../modals/Modal'
import NewVendorContact from './NewVendorContact'
import EditVendorContact from './EditVendorContact'

import EditVendor from './EditVendor'
import NewLocation from '../customer/NewLocation'
import UpdateContact from '../customer/UpdateContact'
import UpdateLocation from '../customer/UpdateLocation'
import AddVendorLocation from './AddVendorLocation'
import EditVendorLocation from './EditVendorLocation'
////import NewLocation from './NewLocation'
//import UpdateContact from './UpdateContact'
//import UpdateLocation from './UpdateLocation'

const Customer = () => {
const nav = useNavigate()
    const vendid = useParams()
 const [customer, setCustomer] = useState([null,null])
 const [contacts, setContacts] = useState([])
 const [locations, setLocations] = useState([])
 const [quotes, setQuotes] = useState([])
 const [allpos, setAllpos] = useState([])
 const [show, setShow] = useState(false)
 const [updateshow, setUpdateshow] = useState(false)
 const [updatevendor, setUpdatevendor] = useState(false)
 const [updatelocation, setUpdatelocation] = useState(false)
 const [locationshow, setLocationshow] = useState(false)
 const [currentcontact, setCurentcontact] = useState({})
 const [currentlocation, setCurentlocation] = useState({})
 const [reset, setReset] = useState(false)


useEffect(() => {

 const getVendor = async() => { 
        const y = {'id':vendid.id}
      const t =  await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getvendorinfo`,y)
       .then(res => {
                    setCustomer(res.data[0]);
                    setContacts(res.data[0].contacts);
                    setLocations(res.data[0].address)
                    if(!res.data[0].address){
                        setLocations(null)
                    }
                    if(!res.data[0].contacts){
                       setContacts(null)
                    }}
                   )
       .catch(err => console.log(err))
      
    }


    const getVendorpos = async() => {
        const y ={
            'id':vendid
        }
        console.log(y)
        //const t =  await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getvendorinfo`,y)
      const t =  await axios.post(`https://cfpapi-6ab062006fa0.herokuapp.com/pos/pogetall`,vendid)
        .then(res => {setAllpos(res.data)})
        .catch(err => console.log(err))
    }
    /*
    const getJobs = async() => { 
        const y = {'id':custoid.id}
       await axios.post(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getcustomerjobs`,y)
       .then(res => {console.log(res.data);setJobs(res.data)})
       .catch(err => console.log(err))
    }
*/
    getVendor()
    getVendorpos()
   // getCustomer()
   // setCurentcontact({})*/
},[reset])



console.log(allpos)



  return (
   <Wrapper padding="10px 10px"  >
            <Modal show={updatevendor} header={`Update Vendor`} onCancel={() => {setUpdatevendor(false)}} ><EditVendor reset={reset} setReset={setReset} vendorid={vendid.id} onCancel={() => {setUpdatevendor(false)}}/></Modal>

<Modal show={show} header={`Add New Contact`} onCancel={() => {setShow(false)}} ><NewVendorContact  reset={reset} setReset={setReset} customerid={vendid.id} onCancel={() => {setShow(false)}}/></Modal>

<Modal show={updateshow} header={`Update Contact`} onCancel={() => {setUpdateshow(false)}} ><EditVendorContact vendorid={vendid.id} contacts={contacts} contact={currentcontact} reset={reset} setReset={setReset}  onCancel={() => {setUpdateshow(false)}}/></Modal>

<Modal show={locationshow} header={`Add New Location`} onCancel={() => {setLocationshow(false)}} ><AddVendorLocation reset={reset} setReset={setReset} customerid={vendid.id} onCancel={() => {setLocationshow(false)}}/></Modal>

<Modal show={updatelocation} header={`Update Location`} onCancel={() => {setUpdatelocation(false)}} ><EditVendorLocation vendorid={vendid.id} locations={locations} location={currentlocation} reset={reset} setReset={setReset} customerid={vendid.id} onCancel={() => {setUpdatelocation(false)}}/></Modal>
  

<Flexed color="#418DA7" margin="0px" fontWeight="600">
<Flex fontSize="calc(.7rem + .4vw)" flex="3">Vendor # 176</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="2">Main Address</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Main Phone</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Contact</Flex>
<Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="1.5">Email</Flex>
</Flexed>

<Flexed color="rgb(0,0,0,.7)" padding="0px" >
<Flex flex="3" fontSize="calc(1.4rem + .4vw)" fontWeight="600" >{customer?.name}<br /><span className="hand" onClick={e => setUpdatevendor(true)} style={{bottom:"0px",width:"100%",position:"relative",fontWeight:"400",fontSize:"calc(.4rem + .4vw)",color:"limegreen",}}><GiHamburgerMenu style={{fontSize:"1rem",position:"relative",bottom:"-3px"}}/>&nbsp;Edit Details</span></Flex>
<Flex fontWeight="600" flex="2" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{locations && locations[0]?.address1 > "" && locations[0]?.address1+'\n' }{locations && locations[0]?.address2 > ""  && locations[0]?.address2+'\n' || ""}{locations && locations[0]?.city > "" && locations[0]?.city}{ locations && locations[0]?.state >"" && ", "+locations[0]?.state}{locations && locations[0]?.zip > "" && locations[0]?.zip} </Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{PhoneNumber(locations && locations[0]?.officephone)}</Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{contacts  && contacts[0]?.name}</Flex>
<Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)" flex="1.5"><span className="hand" onClick={() => window.location = `mailto:${contacts  && contacts[0]?.email}`}>{contacts  && contacts[0]?.email}</span></Flex>
</Flexed>

<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px">
    <Flex textAlign="center">Today</Flex>
    <Flex textAlign="center">This Week</Flex>
    <Flex textAlign="center">Last Week</Flex>
    <Flex textAlign="center">This Month</Flex>
    <Flex textAlign="center">QTD</Flex>
    <Flex textAlign="center">YTD</Flex>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
    <Flex textAlign="center">{currency(0)}</Flex>
    <Flex textAlign="center">{currency(0)}</Flex>
    <Flex textAlign="center">{currency(0)}</Flex>
    <Flex textAlign="center">{currency(0)}</Flex>
    <Flex textAlign="center">{currency(0)}</Flex>
    <Flex textAlign="center">{currency(0)}</Flex>
</Flexed>



<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Contacts</Flex><AddIcon onClick={() => setShow(true)}/>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0">
    <Flex textAlign="left" flex="1.3">Name</Flex>
    <Flex textAlign="left">Title</Flex>
    <Flex textAlign="left">Office Phone</Flex>
    <Flex textAlign="left">Mobile Phone</Flex>
    <Flex  flex="1.4" textAlign="left">Email</Flex>
    <Flex textAlign="left"> Invoices</Flex>
    <Flex flex=".3" textAlign="left"> </Flex>
</Flexed>
{contacts?.map(contact => {
return(
<Flexed bgColor="white" className="hand" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)">
    <Flex textAlign="left" flex="1.3">{contact?.name}</Flex>
    <Flex textAlign="left">{contact?.title}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.officephone)}{contact?.ext > "" && <>  Ext:{contact.ext}</>}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.cell)}</Flex>
    <Flex  flex="1.4" textAlign="left" onClick={() => window.location = `mailto:${contact?.email}`}>{contact?.email}</Flex>
    <Flex >{contact?.invoices}</Flex>
    <Flex flex=".3"><EditIcon onClick={() => {setCurentcontact(contact);setUpdateshow(true)}}/></Flex>
</Flexed>
)
})
}




<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >Locations</Flex><AddIcon onClick={() => setLocationshow(true)}/>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0" >
    <Flex textAlign="left" flex="1.3">Name</Flex>
    <Flex textAlign="left" flex="1.3">Address</Flex>
    <Flex textAlign="left">City</Flex>
    <Flex textAlign="left">State</Flex>
    <Flex textAlign="left">Zip</Flex>
    <Flex textAlign="left">Phone</Flex>
    <Flex textAlign="left">Attn </Flex>
    <Flex flex=".3" textAlign="left"></Flex>
</Flexed>
{locations?.length > 0 && locations?.map(location => {
return(
<Flexed bgColor="white" className="hand" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)">
    <Flex  textAlign="left" flex="1.3">{location?.name}</Flex>
    <Flex textAlign="left" flex="1.3">{location?.address1}&nbsp;{location?.address2}</Flex>
    <Flex textAlign="left">{location?.city}</Flex>
    <Flex textAlign="left">{location?.state}</Flex>
    <Flex textAlign="left">{location?.zip}</Flex>
    <Flex  textAlign="left">{location?.phone}</Flex>
    <Flex >{location?.attn}</Flex>
    <Flex flex=".3"><EditIcon onClick={() => {setCurentlocation(location);setUpdatelocation(true)}}/></Flex>
</Flexed>
)
})
}



<Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 0px 0px">
    <Flex >P.O.'s</Flex><AddIcon onClick={() => nav('/pos/new')}/>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0">
    <Flex textAlign="left" flex=".1">PO #</Flex>

    <Flex textAlign="left" >Items</Flex>

</Flexed>
{allpos?.length > 0 && allpos?.map(item => {
return(
 <Flexed onClick={() => nav(`/po/${item._id}`)} bgColor="white" padding="3px 0px"  color="rgb(0,0,0,.7)"  margin="0px" className="hand" borderBottom="1px solid rgb(0,0,0,.5)"  >
    <Flex textAlign="left" flex=".1">{item.poid}</Flex>
    <Flex textAlign="left">
    {item.items?.map(ite => {return (
    <>{ite.name}<br />
    </>
    )})
}
    
    </Flex>
    
</Flexed>
)
})}



   </Wrapper>
  )
}

export default Customer