import { AiFillFileAdd } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { Flex, Flexed,Fixed,Input,List } from '../../Shared'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Moment from 'react-moment'





const Quotes = () => {

const [jobs, setJobs] = useState()
const [search, setSearch] = useState("")

useEffect(() => {
const getAllJobs = async() => {
    await axios.get(` https://cfpapi-6ab062006fa0.herokuapp.com/jobs/getalljobs`)
    .then( res => setJobs(res.data))
    .catch(err => console.log(err))
}
getAllJobs()
},[])


console.log(jobs)

    const nav = useNavigate()
  return (

    <div style={{padding:"0px 20px",position:"relative"}}>
  
<Flexed>
  <Flex>CFP Quote System</Flex>
</Flexed>

</div>

  )
}

export default Quotes