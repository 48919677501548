import React, { useContext, useEffect, useState } from 'react'
import { AddCustomer, Fixed, Flex, Flexed, Input, List, PhoneNumber } from '../../Shared'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {IoPersonAdd} from 'react-icons/io5'
import UserContext from '../../Context/UserContext'



const Employee = () => {

  const [employees, setEmployees] = useState([])
    const [search, setSearch] = useState("")
    const {userInfo} = useContext(UserContext);
const nav = useNavigate()
useEffect(() => {
const getEmployees = async() => {
//await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/customers/getall')
await axios.get('https://cfpapi-6ab062006fa0.herokuapp.com/employee/get/all')
.then(res => {console.log(res.data);setEmployees(res.data)})
.catch((err) => console.log(err))
}
getEmployees()
},[])
console.log(userInfo)
console.log(employees)
  return (
    <div style={{padding:"0px 20px",position:"relative"}}>
      <Fixed padding="30px 0px 0px 0px" style={{zIndex:"10"}}>
  <Flexed>
            <Flex fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="var(--plsBlue)" textAlign="center" padding="10px 0px">CFP Employee's</Flex>
        </Flexed>
        <Flexed >
          <Flex ><Input type="text" height="30px" placeholder="Search Customers" onChange={e => setSearch(e.target.value)}/></Flex>
         <Flex textAlign="right"><IoPersonAdd  onClick={() => nav('/employees/new')} style={{position:"relative",top:"4px",fontSize:"1.7rem",color:"var(--plsBlue)"}}/></Flex> 
         </Flexed>
<Flexed bgColor="var(--plsGreen)" padding="6px 4px" color="white" margin="0">
<Flex flex=".5">ID</Flex>
  <Flex>Employee</Flex>
  <Flex>Status</Flex>
  <Flex flex="1.5">Phone</Flex>

  <Flex flex="4">Work Email</Flex>
</Flexed>
</Fixed>
<List >
{employees?.filter(item => {return search.toLowerCase() === '' ? item : item.firstName.toLowerCase().includes(search) || item.empId.toString().toLowerCase().includes(search)}).map((employee,index) => {
            return <>
            <Flexed key={employee?.empId} onClick={userInfo.empId === employee?.empId || employee.access !== 106 && userInfo.access === 101 || userInfo.access === 106?  () => nav(`/employees/employee/${employee?._id}`) : () => {}}  className="hand" margin="0" padding="5px 0px" style={{flexWrap:"wrap"}} borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{employee?.empId}</Flex>
            <Flex >{employee?.firstName}&nbsp;{employee?.lastName}</Flex>
            <Flex>{employee?.status ? "Active" : "Inactive"}</Flex>
            <Flex flex="1.5">{PhoneNumber(employee?.cell)}</Flex>
     
            <Flex flex="4">{employee?.workemail}</Flex> 
            </Flexed>
            
            </>
         })}

</List>
{!employees.length && <>No Employees listed at this time</>}

</div>
  )
}

export default Employee